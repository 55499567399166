export default (theme) => ({
  topBtn: {
    width: 56,
    height: 56,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    boxShadow: '0px 2px 15px 0px #c4cee2',
    position: 'fixed',
    zIndex: 99,
    bottom: 50,
    right: 20,
    transition: 'all 0.2s',
    transform: 'scale(0)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  showBtn: {
    transform: 'initial',
  },
});
