import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getMiniQuizQuestions } from '../../services/personality-quiz.service';

const useInterestExplorationStepHook = (selectedLocale) => {
  const history = useHistory();
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await getMiniQuizQuestions();
        setQuestionData(response);
      } catch (err) {
        console.error('Error fetching mini quiz questions:', err);
      }
    };

    fetchQuestions();
  }, [selectedLocale]);

  const handleNext = useCallback(
    (selectedOption, description) => {
      history.push(`/personality-quiz/${selectedOption}`, { description });
    },
    [history]
  );

  const handleBack = useCallback(() => {
    history.push('/personality-quiz');
  }, [history]);

  const handleStartOver = useCallback(() => {
    history.push('/personality-quiz');
  }, [history]);

  return {
    questionData,
    handleNext,
    handleBack,
    handleStartOver,
  };
};

export default useInterestExplorationStepHook;
