import React from 'react';
import { Box, Grid, Link, Typography, Icon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import useVoiceAuthenticationBannerHook from '../voice-authentication-banner/useVoiceAuthenticationBannerHook.js';
import CallCenterSchedullingModal from '../call-center-scheduling-modal/call-center-schedulling-modal';
import useCallUsageBannerHook from '../../components/call-history/components/call-usage-banner/useCallUsageBannerHook';
import useCallCenterSchedulingHook from './useCallCenterSchedulingHook';
import PropTypes from 'prop-types';
import styles from './call-center-scheduling.style.js';
/**
 * Represents the CallCenterScheduling component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.schedule - The schedule data.
 * @param {boolean} props.isClosed - Indicates if the call center is closed.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @returns {JSX.Element} The rendered CallCenterScheduling component.
 */
const CallCenterScheduling = ({ schedule, isClosed, isCallTimeLimitReached, classes }) => {
  const { displayCompleteSchedule, callCenterStatusData } = useCallCenterSchedulingHook({
    schedule,
    isClosed,
    isCallTimeLimitReached,
  });
  const { bannerType } = useCallUsageBannerHook();
  const { openModal, closeModal, callCenterSchedullingModalRef } = useVoiceAuthenticationBannerHook();

  if (!displayCompleteSchedule || !callCenterStatusData) {
    return null;
  }

  return (
    <Grid container>
      <Box className={classes.callCenterSection}>
        <Box className={classes.callCenterHeaderSection}>
          <Typography className={`${classes.callCenterTitle} ${classes[callCenterStatusData.cssClass]}`}>
            <Icon>{callCenterStatusData.icon} </Icon> &nbsp;
            <Typography variant="h2">
              <FormattedMessage id={callCenterStatusData.title} />
            </Typography>
          </Typography>

          <Typography variant="body1" className={classes.callCenterHeaderDescription}>
            <FormattedMessage id={callCenterStatusData.description} />{' '}
            {callCenterStatusData.endTime && callCenterStatusData.endTime + '.'}
            {callCenterStatusData.endTime && callCenterStatusData.subtitle && (
              <FormattedMessage id={callCenterStatusData.subtitle} />
            )}
            {displayCompleteSchedule && displayCompleteSchedule.length && (
              <Link
                component="button"
                type="button"
                className={`${classes.scheduleLink} ${
                  callCenterStatusData.endTime || callCenterStatusData.title === 'call-center-scheduling.closed.title'
                    ? classes.lowercaseText
                    : ''
                }`}
                onClick={openModal}
              >
                <FormattedMessage id="callbanner.viewSchedule" />
              </Link>
            )}
          </Typography>
        </Box>
      </Box>
      <CallCenterSchedullingModal
        ref={callCenterSchedullingModalRef}
        isOpen={false}
        onClose={closeModal}
        contactName={''}
        onContinue={() => {}}
        schedule={displayCompleteSchedule}
        isClosed={isClosed}
        isCallTimeLimitReached={bannerType === 'CALL_TIME_LIMIT_REACHED'}
      />
    </Grid>
  );
};

CallCenterScheduling.propTypes = {
  schedule: PropTypes.array.isRequired,
  isClosed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  bannerType: PropTypes.string.isRequired,
};

export default withStyles(styles)(CallCenterScheduling);
