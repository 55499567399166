import React from 'react';

const ClosedBookIcon = (props) => {
  const { size, fill } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 21 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M4.5 0.492004C3.30653 0.492004 2.16193 0.96611 1.31802 1.81002C0.474106 2.65394 0 3.79853 0 4.992L0 19.992C0 21.1855 0.474106 22.3301 1.31802 23.174C2.16193 24.0179 3.30653 24.492 4.5 24.492H19.5C19.8978 24.492 20.2794 24.334 20.5607 24.0527C20.842 23.7714 21 23.3898 21 22.992C21 22.5942 20.842 22.2127 20.5607 21.9313C20.2794 21.65 19.8978 21.492 19.5 21.492V18.492C19.8978 18.492 20.2794 18.334 20.5607 18.0527C20.842 17.7714 21 17.3898 21 16.992V1.992C21 1.59418 20.842 1.21265 20.5607 0.931344C20.2794 0.65004 19.8978 0.492004 19.5 0.492004L4.5 0.492004ZM4.5 18.492H16.5V21.492H4.5C4.10218 21.492 3.72064 21.334 3.43934 21.0527C3.15804 20.7714 3 20.3898 3 19.992C3 19.5942 3.15804 19.2127 3.43934 18.9313C3.72064 18.65 4.10218 18.492 4.5 18.492ZM6 7.242C6.00053 7.04325 6.07971 6.8528 6.22025 6.71226C6.36079 6.57172 6.55125 6.49253 6.75 6.492H15.75C15.9489 6.492 16.1397 6.57102 16.2803 6.71167C16.421 6.85233 16.5 7.04309 16.5 7.242C16.5 7.44092 16.421 7.63168 16.2803 7.77233C16.1397 7.91299 15.9489 7.992 15.75 7.992H6.75C6.55125 7.99148 6.36079 7.91229 6.22025 7.77175C6.07971 7.63121 6.00053 7.44075 6 7.242ZM6.75 9.492H15.75C15.9489 9.492 16.1397 9.57102 16.2803 9.71167C16.421 9.85233 16.5 10.0431 16.5 10.242C16.5 10.4409 16.421 10.6317 16.2803 10.7723C16.1397 10.913 15.9489 10.992 15.75 10.992H6.75C6.55109 10.992 6.36032 10.913 6.21967 10.7723C6.07902 10.6317 6 10.4409 6 10.242C6 10.0431 6.07902 9.85233 6.21967 9.71167C6.36032 9.57102 6.55109 9.492 6.75 9.492Z"
      />
    </svg>
  );
};

ClosedBookIcon.defaultProps = {
  size: 24,
  fill: 'inherit',
};

export default ClosedBookIcon;
