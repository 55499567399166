import React from 'react';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import WorkIcon from '@material-ui/icons/Work';
import { Image } from '@react-pdf/renderer';
import {
  BooksIcon,
  ClosedBookIcon,
  EnrichmentIcon,
  FilmIcon,
  GraduationCapIcon,
  OpenFolderIcon,
  ReentryIcon,
  RehabilitationIcon,
  SkillsIcon,
  SpaIcon,
  UserIcon,
} from '../../../shared/icons';

export class Constant {
  static HIDDEN_MESSAGE_MASK = '***MESSAGE HIDDEN***';
  static MESSAGE_POLL_INTERVAL = 20000;
  static ANNOUNCEMENT_POLL_INTERVAL = 20000; //adding this because there is no socket implementation for announcement
  static BLACK_BAR_TIMEOUT = 30000;
  static PROFILE = process.env.REACT_APP_PROFILE;
  static PARTNER_LOGOUT_WINDOW_TIME = window.PARTNER_LOGOUT_WINDOW_TIME || 5000;
  static POLL_CONFIG = window.POLL_CONFIG
    ? window.POLL_CONFIG
    : {
        APPLICATION_WIDE_POLL_INTERVAL: 120000,
        ENTERTAINMENT_POLL_INTERVAL: 120000,
        USE_POLLING: true,
        USE_ANNOUNCEMENT_POLLING: true,
      };
  static PENDO_CONFIG = window.PENDO_CONFIG
    ? window.PENDO_CONFIG
    : {
        isEnable: false,
        ENVIRONMENT: '',
        API_KEY: '',
      };
  static DEFAULT_POLL_INTERVAL = 120000;
  static FALLBACK_TIME_FETCH = 5000;
  static VIDEO_RESOURCE_URL = process.env.REACT_APP_ENTERTAINMENT_VIDEO_RESOURCE_URL;
  static GAME_RESOURCE_URL = process.env.REACT_APP_ENTERTAINMENT_GAME_RESOURCE_URL;
  static RADIO_RESOURCE_URL = process.env.REACT_APP_ENTERTAINMENT_RADIO_RESOURCE_URL;
  static VOICE_CALL_SCHEDULE_CUTOFF_TIME =
    window.VOICE_CALL_SCHEDULE_CUTOFF_TIME || process.env.REACT_APP_VOICE_CALL_SCHEDULE_CUTOFF_TIME;
  static VOICE_CALL_SCHEDULE_POLLING_INTERVAL = window.VOICE_CALL_SCHEDULE_POLLING_INTERVAL || 30000;
  static VOICE_CALL_HANGUP_TIMEOUT = window.VOICE_CALL_HANGUP_TIMEOUT || 90000;
  static CONTENT_AREAS_ENUM = process.env.REACT_APP_CONTENT_AREAS
    ? JSON.parse(process.env.REACT_APP_CONTENT_AREAS)
    : {
        Rehab: 1,
        Entertainment: 3,
        ReEntry: 4,
        Education: 6,
        Enrichment: 2,
        Workforce: 5,
        Misc: 9,
      };

  static SERVICE_CATEGORIES_ENUM = process.env.REACT_APP_SERVICE_CATEGORIES
    ? JSON.parse(process.env.REACT_APP_SERVICE_CATEGORIES)
    : {
        EDUCATION: 1,
        REHABILITATION: 2,
        PERSONAL_DEVELOPMENT: 3,
        WORKFORCE_SKILLS: 4,
        DOCUMENTRIES: 5,
        RESOURCES: 6,
        MASTER_CLASS: 15,
      };

  static ROUTES = {
    BOOKMARKS: 'bookmarks',
    CAREERS: 'careers',
    HOME: 'home',
    MY_PROFILE: 'my-profile',
  };

  static Tag = {
    RESOURCE_PROVIDER_ESSENTIAL_ED: 'RESOURCE_PROVIDER_ESSENTIAL_ED',
  };

  static IDLE_TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT;
  static VIDEO_IDLE_TIMEOUT = process.env.REACT_APP_VIDEO_IDLE_TIMEOUT;
  static CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

  /**
   * @name FEATURES_ENUM
   * @desc ENUM for different location features.
   * @return {object}
   */
  static get FEATURES_ENUM() {
    return {
      MESSAGING_PERSON: 'Messaging_Person',
      FNF_MESSAGING: 'FF_Messaging',
      CAREER_PATH: 'Career_Path',
      EXPLORE: 'Explore',
      MESSAGING: 'Messaging',
      PLAY: 'Play',
      ACHIEVEMENTS: 'Achievements',
      FORMS: 'Forms',
      INCENTIVE_ENGINE: 'Incentive_Engine',
      VOICE_CALLS: 'Voice_Calls',
      VOICE_CALLS_SCHEDULE: 'Voice_Calls_Schedule',
      PLAY_SCHEDULE: 'Play_Schedule',
      RESUME_BUILDER: 'Resume_Builder',
      MILESTONE_MAPPER: 'Milestone_Mapper',
      FEATURED_CONTENT: 'Featured_Content',
      CAREER_COMPASS: 'Career_Compass',
    };
  }

  /**
   * @name PDQ_ENUM
   * @desc ENUM for different PDQ status.
   * @return {object}
   */
  static get PDQ_ENUM() {
    return {
      NOT_STARTED: 'NOT_STARTED',
      IN_PROGRESS: 'IN_PROGRESS',
      SUBMITTED: 'SUBMITTED',
      COMPLETED: 'COMPLETED',
    };
  }

  /**
   * @name ONBOARDING_STATUS
   * @desc ENUM for different ONBOARDING status.
   * @return {object}
   */
  static get ONBOARDING_STATUS() {
    return {
      COMPLETED: 'COMPLETED',
      SKIPPED: 'SKIPPED',
    };
  }

  /**
   * @name MY_PROFILE_QUESTION_SECTIONS
   * @desc ENUM for different question sections of my profile.
   * @return {object}
   */
  static get MY_PROFILE_QUESTION_SECTIONS() {
    return {
      DEMOGRAPHIC: 'Demographic',
      PERSONALITY: 'Personal',
    };
  }

  /**
   * @name MY_PROFILE_QUESTION_TYPES
   * @desc ENUM for different question types of my profile.
   * @return {object}
   */
  static get MY_PROFILE_QUESTION_TYPES() {
    return {
      DROPDOWN: 'DROPDOWN',
      SCALE: 'SCALE',
    };
  }

  /**
   * @name TRACKING_TYPES
   * @desc ENUM for different TRACKING TYPES.
   * @return {object}
   */
  static get TRACKING_TYPES() {
    return {
      LANGUAGE: 'language',
      LOGIN_CREDENTIALS: 'login-credentials',
      LOGOUT: 'logout',
      HOMEPAGE: 'homepage',
      PROGRAM: 'program',
      CAREER_DIRECTORY: 'career-directory',
      SERVICE: 'service',
      FRIENDS_AND_FAMILY: 'friends-and-family',
      STAFF_MESSAGING: 'staff-messaging',
      ENTERTAINMENT: 'entertainment',
      ACHIEVEMENT: 'achievement',
      FORM: 'form',
      PDQ: 'pdq',
      INTEREST_PROFILER: 'interest-profiler',
      CAREER_BOOKMARK: 'career-bookmark',
      PROGRESS_REPORT: 'progress-report',
      FEATURED_CONTENT: 'featured-content',
      COURSE: 'course',
      TOOL: 'tool',
      JOB_ZONE_QUESTIONAIRRE: 'job-zone-questionairre',
      CAREER_PATHS: 'career-paths',
      CAREER_COMPASS: 'career-compass',
    };
  }

  /**
   * @name PERSONALITY_AREAS
   * @desc ENUM for interest profiler PERSONALITY AREA.
   * @return {object}
   */
  static get PERSONALITY_AREAS() {
    return {
      REALISTIC: 'REALISTIC',
      INVESTIGATIVE: 'INVESTIGATIVE',
      ARTISTIC: 'ARTISTIC',
      SOCIAL: 'SOCIAL',
      CONVENTIONAL: 'CONVENTIONAL',
      ENTERPRISING: 'ENTERPRISING',
    };
  }

  /**
   * @name PAGINATED_ITEM
   * @property GET
   * @desc No. of items set for paginated items.
   * @return {number}
   */
  static get PAGINATED_ITEM() {
    return 100;
  }

  static get ANALYTICS_NO_OF_DAYS() {
    return 7;
  }

  /**
   * @name CHART_FORMAT
   * @desc All formatting being used in chart.
   * @return {object}
   */
  static get CHART_FORMAT() {
    return {
      FORMAT_7: 'D/MM',
      FORMAT_30: 'M/D/YY',
      FORMAT_365: 'YYYY-MM-DD',
      FORMAT_DEFAULT: 'YYYY-MM-DD',
      CHECKS: {
        FORMAT_7: 'D/MM',
        FORMAT_30: 'M/D/YY',
        FORMAT_365: 'DD/MMM/YY',
        FORMAT_DEFAULT: 'DD/MMM/YY',
      },
    };
  }

  /**
   * @name TRACKER_THRESHOLD
   * @desc Tracker threshold as specified in requirements.
   * @return {object}
   */
  static get TRACKER_THRESHOLD() {
    return {
      Interval: 10000, // MILLISECONDS
      // SECONDS
      Pathway: 7200,
      Explore: 7200,
      Achievements: 7200,
      Video: 7200,
      Radio: 7200,
      Games: 7200,
      Messaging: 7200,
      Forms: 7200,
    };
  }

  /**
   * @name TRACKER_MIN_TIME
   * @desc Tracker minimum time for logging.
   * @return {object}
   */
  static get TRACKER_MIN_TIME() {
    return {
      // SECONDS
      Pathway: 86400,
      Explore: 86400,
      Achievements: 86400,
      Video: 120,
      Radio: 120,
      Games: 120,
      Messaging: 300,
      Forms: 300,
    };
  }

  /**
   * @name SORT_TYPE
   * @desc Sort type standards being used in entire system.
   * @return {object}
   */
  static get SORT_TYPE() {
    return {
      AZ: 1,
      MOST_RECENT: 2,
    };
  }

  /**
   * @name VERSION
   * @desc Application version, this version is retrieved from building origin's environment.
   * @return {string}
   */
  static get VERSION() {
    return process.env.REACT_APP_VERSION;
  }

  /**
   * @name EXPLORE_SERVICES
   * @desc Services available at explore page.
   * @return {object}
   */
  static get EXPLORE_SERVICES() {
    return {
      Kolibri: 'Kolibri',
      NCL: 'National Corrections Library (NCL)',
      BEING_FREE: 'Being Free',
      CALCULATOR: 'Calculator',
      ENGEN: 'Engen',
      HANDBOOK: {
        name: 'handbook',
        icon: 'library_books',
      },
      CAREY_GUIDES: 'Carey Guides',
    };
  }

  /**
   * @name SERVICE_CATEGORY
   * @desc Service category area enum being maintained within system.
   * @return {object}
   */
  static get SERVICE_CATEGORY() {
    const serviceCategories = Constant.SERVICE_CATEGORIES_ENUM;
    return {
      [serviceCategories.EDUCATION]: {
        name: 'Educational',
        icon: <GraduationCapIcon />,
        iconWithProps: (props) => <GraduationCapIcon {...props} />,
      },
      [serviceCategories.REHABILITATION]: {
        name: 'Wellness',
        icon: <SpaIcon />,
        iconWithProps: (props) => <SpaIcon {...props} />,
      },
      [serviceCategories.PERSONAL_DEVELOPMENT]: {
        name: 'Personal Development',
        icon: <UserIcon />,
        iconWithProps: (props) => <UserIcon {...props} />,
      },
      [serviceCategories.WORKFORCE_SKILLS]: {
        name: 'Workforce Skills',
        icon: <SkillsIcon />,
        iconWithProps: (props) => <SkillsIcon {...props} />,
      },
      [serviceCategories.DOCUMENTRIES]: {
        name: 'Documentaries',
        icon: <FilmIcon />,
        iconWithProps: (props) => <FilmIcon {...props} />,
      },
      [serviceCategories.RESOURCES]: {
        name: 'Resources',
        icon: <OpenFolderIcon />,
        iconWithProps: (props) => <OpenFolderIcon {...props} />,
      },
      [serviceCategories.MASTER_CLASS]: {
        name: 'Master Class',
        icon: <ClosedBookIcon />,
        iconWithProps: (props) => <ClosedBookIcon {...props} />,
      },
    };
  }

  /**
   * @name CONTENT_AREA
   * @desc Content area enum being maintained within system.
   * @return {object}
   */
  static get CONTENT_AREA() {
    const contentAreas = Constant.CONTENT_AREAS_ENUM;
    return {
      [contentAreas.Rehab]: {
        id: contentAreas.Rehab,
        key: 'rehabilitation',
        icon: 'ic-widgets',
        class: 'rehab-chip',
        name: 'Rehabilitation',
        roundIcon: 'rehab-icon',
        bgColor: '#E3A634',
        shield: 'rehabilation-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 'rehabilitation' }),
        icons: {
          main: <RehabilitationIcon />,
          mainWithSize: (size) => <RehabilitationIcon size={size} />,
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path
                fill="#0a827d"
                fill-rule="evenodd"
                d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
              />
            </svg>
          ),
          round: (
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 34,
                backgroundColor: '#ebf7f3',
                border: '1px solid #b2dacf',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path
                  fill="#0a827d"
                  fill-rule="evenodd"
                  d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
                />
              </svg>
            </div>
          ),
          white: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path
                fill="#FFFFFF"
                fill-rule="evenodd"
                d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
              />
            </svg>
          ),
          dark: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path
                fill="#0a827d"
                fill-rule="evenodd"
                d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
              />
            </svg>
          ),
          darkCustom: (size) => (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
              <path
                fill="#0a827d"
                fill-rule="evenodd"
                d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
              />
            </svg>
          ),
          pdf: (size) => (
            <Image
              alt=""
              style={{
                width: size,
                height: size,
              }}
              src={`/assets/icons/bucket-rehab-color.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          color: 'white',
          borderColor: '#017e5b',
          backgroundColor: '#017e5b',
        },
        filter: {
          color: '#017e5b',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>62ABBDA2-B676-4553-93DC-B3479CD36A4D</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Shape" fill="#017E67" transform="translate(2.000000, 0.750000)">
                  <path d="M10.8000004,0.5 C15.4440006,0.5 19.2000008,4.25600015 19.2000008,8.90000033 C19.2000008,12.2480005 17.2320007,15.1400006 14.4000006,16.4840006 L14.4000006,16.4840006 L14.4000006,22.1000009 L6.00000024,22.1000009 L6.00000024,18.5 L4.80000019,18.5 C3.48000014,18.5 2.4000001,17.4200007 2.4000001,16.1000006 L2.4000001,16.1000006 L2.4000001,12.5 L0.600000024,12.5 C0.108000004,12.5 -0.180000007,11.9360005 0.120000005,11.5400004 L0.120000005,11.5400004 L2.4240001,8.46800032 C2.6400001,4.02800014 6.30000025,0.5 10.8000004,0.5 Z M13.152154,5.38373159 C12.130274,5.38373159 11.15021,6.03616019 10.8000004,6.92733238 C10.4497909,6.03616019 9.4686815,5.38373159 8.44784685,5.38373159 C7.11861873,5.38373159 6.09569327,6.3778105 6.09569327,7.69913277 C6.09569327,9.51492183 8.07829739,10.919084 10.8000004,13.6162691 C13.5217035,10.919084 15.5043076,9.51492183 15.5043076,7.69913277 C15.5043076,6.3778105 14.4813821,5.38373159 13.152154,5.38373159 Z" />
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.Entertainment]: {
        id: contentAreas.Entertainment,
        key: 'entertainment',
        icon: 'ic-entertainment',
        class: 'entertainment-chip',
        name: 'Entertainment',
        roundIcon: 'entertainment-icon',
        bgColor: '#c92563',
        shadeColor: '#fcf3f6',
        shield: null,
        getTranslatedName: (intl) => intl.formatMessage({ id: 'entertainment' }),
        icons: {
          icon: <img alt="" src={`/assets/icons/ic-entertainment.png`} />,
          round: <img alt="" src={`/assets/icons/entertainment-icon.png`} />,
          white: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-entertainment-white.png`}
            />
          ),
          dark: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-entertainment.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#cb2061',
          color: 'white',
          backgroundColor: '#cb2061',
        },
        filter: {
          color: '#cb2061',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>4E34F850-826D-4FF1-9D50-8AF94BE59D5E</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="bucket/entertain_color">
                  <g id="Image/ic_music_note" transform="translate(0.000000, 0.000000)">
                    <g id="ic_music_note">
                      <polygon id="Bounds" points="0 0 23.5215349 0 23.5215349 23.5215349 0 23.5215349" />
                      <path
                        d="M11.7607674,2.94019186 L11.7607674,13.2798666 C11.1825297,12.9466448 10.5160862,12.7408314 9.80063952,12.7408314 C7.63469819,12.7408314 5.88038371,14.4951459 5.88038371,16.6610872 C5.88038371,18.8270285 7.63469819,20.581343 9.80063952,20.581343 C11.9665809,20.581343 13.7208953,18.8270285 13.7208953,16.6610872 L13.7208953,6.86044767 L17.6411511,6.86044767 L17.6411511,2.94019186 L11.7607674,2.94019186 L11.7607674,2.94019186 Z"
                        id="Icon"
                        fill="#C92563"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.ReEntry]: {
        id: contentAreas.ReEntry,
        key: 'reentry',
        icon: 'ic-launch',
        class: 'reEntry-chip',
        name: 'Re-Entry',
        roundIcon: 're-entry-icon',
        bgColor: '#D3682F',
        shield: 're-entry-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 're-entry' }),
        icons: {
          main: <ReentryIcon />,
          mainWithSize: (size) => <ReentryIcon size={size} />,
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
              <path
                fill="#8a51dd"
                fill-rule="evenodd"
                d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
              />
            </svg>
          ),
          round: (
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 34,
                backgroundColor: '#F4EEFC',
                border: '1px solid #8743DF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
                <path
                  fill="#8A51DD"
                  fill-rule="evenodd"
                  d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
                />
              </svg>
            </div>
          ),
          white: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
              <path
                fill="#FFFFFF"
                fill-rule="evenodd"
                d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
              />
            </svg>
          ),
          dark: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
              <path
                fill="#8A51DD"
                fill-rule="evenodd"
                d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
              />
            </svg>
          ),
          darkCustom: (size) => (
            <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`}>
              <path
                fill="#8a51dd"
                fill-rule="evenodd"
                d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
              />
            </svg>
          ),
          pdf: (size) => (
            <Image
              alt=""
              style={{
                width: size,
                height: size,
              }}
              src={`/assets/icons/bucket-re-entry-color.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#8a51dd',
          color: 'white',
          backgroundColor: '#8a51dd',
        },
        filter: {
          color: '#8a51dd',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>BCE797CE-1FA5-40CA-983A-9D963E53561E</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Re-Entry" fill="#8A51DD">
                  <path d="M16.3043478,2 L16.3043478,2.013873 L21.0869565,2.013873 L21.0869565,17.2380952 L23,17.2380952 L23,19.1428571 L19.173913,19.1428571 L19.173913,3.9186349 L9.66028107,3.9186349 L9.66028107,19.1428571 L7.69565217,19.1428571 L1,22 L1,4.7889806 L7.69565217,2 L16.3043478,2 Z M12.9565217,10.5714286 L13.7038043,12.2083333 L15.3478261,12.952381 L13.7038043,13.6964286 L12.9565217,15.3333333 L12.2092391,13.6964286 L10.5652174,12.952381 L12.2092391,12.2083333 L12.9565217,10.5714286 Z M4.82608696,13.4285714 L2.91304348,13.4285714 L2.91304348,15.3333333 L4.82608696,15.3333333 L4.82608696,13.4285714 Z M16.3043478,7.71428571 L16.9037681,9.02222222 L18.2173913,9.61904762 L16.9037681,10.215873 L16.3043478,11.5238095 L15.7049275,10.215873 L14.3913043,9.61904762 L15.7049275,9.02222222 L16.3043478,7.71428571 Z" />
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.Education]: {
        id: contentAreas.Education,
        key: 'education',
        icon: 'ic-school',
        class: 'education-chip',
        name: 'Education',
        roundIcon: 'education-icon',
        bgColor: '#4E9D97',
        shadeColor: '#4E9D97',
        shield: 'education-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 'education' }),
        icons: {
          main: <BooksIcon />,
          mainWithSize: (size) => <BooksIcon size={size} />,
          icon: <img alt="" src={`/assets/icons/ic-school.png`} />,
          round: <img alt="" src={`/assets/icons/education-icon.png`} />,
          white: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-school-white.png`}
            />
          ),
          dark: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-school.png`}
            />
          ),
          darkCustom: (size) => (
            <img
              alt=""
              style={{
                width: size,
              }}
              src={`/assets/icons/ic-school.png`}
            />
          ),
          pdf: (size) => (
            <Image
              alt=""
              style={{
                width: size,
                height: size,
              }}
              src={`/assets/icons/bucket-education-color.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#396993',
          color: 'white',
          backgroundColor: '#396993',
        },
        filter: {
          color: '#396993',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>6855549D-A6D9-4662-8067-99DC4C61C8CF</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="bucket/education_color">
                  <g id="Social/ic_school" transform="translate(-0.537854, 0.416886)">
                    <g id="ic_school">
                      <polygon id="Bounds" points="0 0 23.9040005 0 23.9040005 23.9040005 0 23.9040005" />
                      <path
                        d="M4.9800001,13.1272803 L4.9800001,17.1112803 L11.9520002,20.9160004 L18.9240004,17.1112803 L18.9240004,13.1272803 L11.9520002,16.9320003 L4.9800001,13.1272803 L4.9800001,13.1272803 Z M11.9520002,2.98800006 L0.99600002,8.96400018 L11.9520002,14.9400003 L20.9160004,10.0496402 L20.9160004,16.9320003 L22.9080004,16.9320003 L22.9080004,8.96400018 L11.9520002,2.98800006 L11.9520002,2.98800006 Z"
                        id="Icon"
                        fill="#3C6A91"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.Enrichment]: {
        id: contentAreas.Enrichment,
        key: 'enrichment',
        icon: 'ic-local-movies',
        class: 'enrichment-chip',
        name: 'Enrichment',
        roundIcon: 'enrich-icon',
        bgColor: '#1C79BE',
        shadeColor: '#1C79BE',
        shield: 'enrichment-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 'enrichment' }),
        icons: {
          main: <EnrichmentIcon />,
          mainWithSize: (size) => <EnrichmentIcon size={size} />,
          icon: (
            <WbIncandescentIcon
              style={{
                color: '#737982',
                fontSize: 20,
                transform: 'rotate(180deg)',
                marginTop: -4,
              }}
            />
          ),
          round: (
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 34,
                backgroundColor: '#F4F5F6',
                border: '1px solid #D2D4D7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WbIncandescentIcon
                style={{
                  color: '#737982',
                  fontSize: 20,
                  transform: 'rotate(180deg)',
                  marginTop: -4,
                }}
              />
            </div>
          ),
          white: (
            <WbIncandescentIcon
              style={{
                color: 'white',
                fontSize: 20,
                transform: 'rotate(180deg)',
                marginTop: -4,
              }}
            />
          ),
          dark: (
            <WbIncandescentIcon
              style={{
                color: '#737982',
                fontSize: 20,
                transform: 'rotate(180deg)',
                marginTop: -4,
              }}
            />
          ),
          darkCustom: (size) => (
            <WbIncandescentIcon
              style={{
                color: '#737982',
                fontSize: size,
                transform: 'rotate(180deg)',
                marginTop: -4,
              }}
            />
          ),
          pdf: (size) => (
            <Image
              alt=""
              style={{
                width: size,
                height: size,
              }}
              src={`/assets/icons/bucket-enrich-color.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#4B6A88',
          color: 'white',
          backgroundColor: '#4B6A88',
        },
        filter: {
          color: '#4B6A88',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>E8EED933-A8E7-4C50-89F7-825333CC21B2</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="bucket/enrich_color" fill="#737982">
                  <g id="Image/ic_wb_incandescent">
                    <path
                      d="M3.55,18.04 L4.96,19.45 L6.75,17.65 L5.34,16.24 L3.55,18.04 L3.55,18.04 Z M11,21.95 L13,21.95 L13,19 L11,19 L11,21.95 L11,21.95 Z M4,10 L1,10 L1,12 L4,12 L4,10 L4,10 Z M15,5.81 L15,1 L9,1 L9,5.81 C7.21,6.85 6,8.78 6,11 C6,14.31 8.69,17 12,17 C15.31,17 18,14.31 18,11 C18,8.78 16.79,6.85 15,5.81 L15,5.81 Z M20,10 L20,12 L23,12 L23,10 L20,10 L20,10 Z M17.24,17.66 L19.03,19.46 L20.44,18.05 L18.64,16.26 L17.24,17.66 L17.24,17.66 Z"
                      id="Icon"
                      transform="translate(12.000000, 11.475000) rotate(-180.000000) translate(-12.000000, -11.475000) "
                    />
                  </g>
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.Workforce]: {
        id: contentAreas.Workforce,
        key: 'workforce',
        icon: 'ic-work',
        class: 'workforce-chip',
        name: 'Workforce Skills',
        roundIcon: 'work-icon',
        bgColor: '#0F445A',
        shadeColor: '#FDF4F2',
        shield: 'workforce-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 'workforce skills' }),
        icons: {
          main: <SkillsIcon />,
          mainWithSize: (size) => <SkillsIcon size={size} />,
          icon: (
            <WorkIcon
              style={{
                color: '#F14B32',
                fontSize: 20,
              }}
            />
          ),
          round: (
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 34,
                backgroundColor: '#fdf4f2',
                border: '1px solid #FACAC2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WorkIcon
                style={{
                  color: '#F14B32',
                  fontSize: 20,
                }}
              />
            </div>
          ),
          white: (
            <WorkIcon
              style={{
                color: 'white',
                fontSize: 20,
              }}
            />
          ),
          dark: (
            <WorkIcon
              style={{
                color: '#f15b4a',
                fontSize: 20,
              }}
            />
          ),
          darkCustom: (size) => (
            <WorkIcon
              style={{
                color: '#f15b4a',
                fontSize: size,
              }}
            />
          ),
          pdf: (size) => (
            <Image
              alt=""
              style={{
                width: size,
                height: size,
              }}
              src={`/assets/icons/bucket-work-color.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#F14B32',
          color: 'white',
          backgroundColor: '#F14B32',
        },
        filter: {
          color: '#F14B32',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>E8C061C7-1E28-412A-A771-314C785F351D</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="bucket/work_color">
                  <g id="Action/ic_work" transform="translate(-0.000000, -0.000000)">
                    <g id="ic_work">
                      <polygon id="Bounds" points="0 0 23.9040005 0 23.9040005 23.9040009 0 23.9040009" />
                      <path
                        d="M19.9200004,5.97600022 L15.9360003,5.97600022 L15.9360003,3.98400014 C15.9360003,2.8784401 15.0495603,1.99200007 13.9440003,1.99200007 L9.9600002,1.99200007 C8.85444017,1.99200007 7.96800016,2.8784401 7.96800016,3.98400014 L7.96800016,5.97600022 L3.98400008,5.97600022 C2.87844006,5.97600022 2.00196004,6.86244025 2.00196004,7.96800029 L1.99200004,18.9240007 C1.99200004,20.0295607 2.87844006,20.9160008 3.98400008,20.9160008 L19.9200004,20.9160008 C21.0255604,20.9160008 21.9120004,20.0295607 21.9120004,18.9240007 L21.9120004,7.96800029 C21.9120004,6.86244025 21.0255604,5.97600022 19.9200004,5.97600022 L19.9200004,5.97600022 Z M13.9440003,5.97600022 L9.9600002,5.97600022 L9.9600002,3.98400014 L13.9440003,3.98400014 L13.9440003,5.97600022 L13.9440003,5.97600022 Z"
                        id="Icon"
                        fill="#F15B4A"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ),
        },
      },
      [contentAreas.Misc]: {
        id: contentAreas.Misc,
        key: 'misc',
        icon: 'ic-category',
        class: 'misc-chip',
        name: 'Miscellaneous',
        roundIcon: 'misc-icon',
        bgColor: '#2d2d2d',
        shadeColor: '2d2d2d61',
        shield: 'misc-shield',
        getTranslatedName: (intl) => intl.formatMessage({ id: 'miscellaneous' }),
        icons: {
          icon: <img alt="" src={`/assets/icons/ic-category.png`} />,
          round: <img alt="" src={`/assets/icons/misc-icon.png`} />,
          white: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-category-white.png`}
            />
          ),
          dark: (
            <img
              alt=""
              style={{
                width: 20,
              }}
              src={`/assets/icons/ic-category.png`}
            />
          ),
        },
        chip: {
          border: '1px solid',
          borderColor: '#000000',
          color: 'white',
          backgroundColor: '#000000',
        },
        filter: {
          color: '#000000',
        },
        svg: {
          Explore: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <title>6185F7D5-7D8B-4DCF-BDDC-F4D3ACC17207</title>
              <desc>Created with sketchtool.</desc>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="ic/category-24px" fill="#000000">
                  <path
                    d="M17.5,13 C19.9852814,13 22,15.0147186 22,17.5 C22,19.9852814 19.9852814,22 17.5,22 C15.0147186,22 13,19.9852814 13,17.5 C13,15.0147186 15.0147186,13 17.5,13 Z M11,13.5 L11,21.5 L3,21.5 L3,13.5 L11,13.5 Z M12,2 L17.5,11 L6.5,11 L12,2 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </svg>
          ),
        },
      },
    };
  }

  /**
   * @MESSAGE_TYPES
   * @return {object}
   */
  static get MESSAGE_TYPES() {
    return {
      EVENT: 'EVENT',
      MESSAGE: 'MESSAGE',
    };
  }

  /**
   * @name FNF_RELATIONSHIP_STATUS
   * @return {object}
   */
  static get FNF_RELATIONSHIP_STATUS() {
    return {
      ACCEPTED: 'ACCEPTED',
      BLOCKED: 'BLOCKED',
      REJECTED: 'REJECTED',
      UNBLOCKED: 'UNBLOCKED',
    };
  }

  /**
   * @name GRAPHS
   * @property GET
   * @return {object} Graphs being used in system
   */
  static get GRAPHS() {
    return {
      BAR: 0,
      LINE: 1,
    };
  }

  /**
   * @name ERROR
   * @property GET
   * @return {object} Errors handling being done in portal.
   */
  static get ERROR() {
    return {
      AUTHORIZE_ERROR: {
        STATUS: 401,
        MESSAGE: 'You are not authorized to access this portal.',
      },
      SESSION_ERROR: {
        STATUS: 400,
        MESSAGE: 'Your session has expired.',
      },
      GENERAL_ERROR: {
        STATUS: 500,
        MESSAGE: 'Something went wrong. Please try again later.',
      },
    };
  }

  /**
   * @name KEYCLOAK
   * @property GET
   * @return {object} Key clock constants being used in portal.
   */
  static get KEYCLOCK() {
    return {
      TOKEN_EXPIRED: 'onTokenExpired',
      LOGOUT: 'onAuthLogout',
      TOKEN_REFRESH_ERROR: 'onAuthRefreshError',
      AUTH_SUCCESS: 'onAuthSuccess',
      AUTH_ERROR: 'onAuthError',
    };
  }

  /**
   * @name STYLES
   * @property GET
   * @return {object} Styling constant being used in portal.
   */
  static get STYLES() {
    return {
      DRAWER_WIDTH: 250,
      DRAWER_WIDTH_FULL_SCREEN: 20,
      DRAWER_CLOSE_WIDTH: 65,
      DRAWER_CLOSE_WIDTH_V2: 77,
      DRAWER_ITEM_HEIGHT: 120,
    };
  }

  static get PLAY() {
    return {
      VIDEO: 'Videos',
      GAME: 'Games',
      RADIO: 'Radio',
    };
  }

  /**
   * @name CAREER_ZONES
   * @property GET
   * @return {Array} zone constant being used in career pages.
   */
  static get CAREER_ZONES() {
    return [
      { label: 'Zone 1', value: '1' },
      { label: 'Zone 2', value: '2' },
      { label: 'Zone 3', value: '3' },
      { label: 'Zone 4', value: '4' },
      { label: 'Zone 5', value: '5' },
    ];
  }

  /**
   * @name MATCHING_CAREERS_FITS
   * @property GET
   * @return {object} matching careers fits constant being used in career pages.
   */
  static get MATCHING_CAREERS_FITS() {
    return {
      Best: {
        id: 'Best',
        color: '#B1562F',
        text: 'bestFit',
      },
      Great: {
        id: 'Great',
        color: '#1A5E3D',
        text: 'greatFit',
      },
      Good: {
        id: 'Good',
        color: '#5E4A00',
        text: 'goodFit',
      },
    };
  }

  /**
   * @name ASSESSMENTS
   * @desc ENUM for different assessments data.
   * @return {object}
   */
  static get ASSESSMENTS() {
    return {
      PDQ: {
        ACTIVITY_TEMPLATE_ID: process.env.REACT_APP_ASSESSMENT_ACTIVITY_TEMPLATE_ID_PDQ,
        ASSESSMENT_URL: '/learnosity/index.html',
      },
    };
  }

  /**
   * @name ROLE_TYPES
   * @desc ENUM for different role types.
   * @return {object}
   */
  static get ROLE_TYPES() {
    return {
      ATTORNEY: 'Attorney',
    };
  }

  static get CALL_CENTER_STATUS() {
    return {
      OPEN: 'Open',
      CLOSED: 'Closed',
      CLOSING_SOON: 'Closing soon',
      CALL_TIME_LIMIT_REACHED: 'Call time limit reached',
    };
  }

  /**
   * @name LANGUAGES
   * @desc ENUM for different languages.
   * @return {object}
   */
  static get LANGUAGES() {
    return {
      ENGLISH: { code: 'en', name: 'English' },
      SPANISH: { code: 'es', name: 'Español' },
    };
  }

  static SOCKET_EVENT_TYPES = {
    MESSAGE: 'message',
    ANNOUNCEMENT: 'announcement',
  };

  static VOICE_PROFILE_ID_STATUS = {
    RERECORD_AUDIO: 'RERECORD_AUDIO',
    PROFILE_CREATED: 'PROFILE_CREATED',
    RE_RECORD: 'RE_RECORD',
    DUPLICATE_PROFILE: 'DUPLICATE_PROFILE',
    PROCESSING_FAILED: 'PROCESSING_FAILED',
  };

  static TAGS_KEYS = {
    CERTIFICATE: 'CERTIFICATE',
    DURATION: 'DURATION',
    CERTIFICATE_UNAVAILABLE: 'CERTIFICATE_UNAVAILABLE',
  };

  static PROGRAM_DURATIONS = {
    LONG_DURATION: 'LONG_DURATION',
    SHORT_DURATION: 'sHORT_DURATION',
  };

  static CATEGORY_MAP = [
    {
      name: 'Rehabilitation',
      keywords: ['rehabilitation', 'rehab'],
      backgroundClass: 'bgRehabilitation',
    },
    {
      name: 'Workforce',
      keywords: ['workforce', 'employment', 'job', 'employability'],
      backgroundClass: 'bgWorkforce',
    },
    {
      name: 'Education',
      keywords: ['education', 'learning', 'school', 'study', 'academic'],
      backgroundClass: 'bgEducation',
    },
    {
      name: 'Personal Growth',
      keywords: ['growth', 'development', 'self-improvement'],
      backgroundClass: 'bgPersonalGrowth',
    },
    {
      name: 'Resource',
      keywords: ['resource', 'support', 'assistance'],
      backgroundClass: 'bgResource',
    },
  ];

  static RIASEC_MAP = {
    R: 'realistic',
    I: 'investigative',
    A: 'artistic',
    S: 'social',
    E: 'enterprising',
    C: 'conventional',
  };
}
