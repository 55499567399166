import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Divider, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Constant } from '../../../../../shared';
import styles from './interest-exploration-step.styles';
import InterestProfilerAction from '../interest-profiler-action/interest-profiler-action';
import StackedCard from '../stacked-card/stacked-card';
import useInterestExplorationStepHook from './useInterestExplorationStepHook';
import FeatureNavigation from '../feature-navigation/feature-navigation';

const InterestExplorationStep = ({ classes, selectedLocale }) => {
  const intl = useIntl();
  const { questionData, handleNext, handleBack, handleStartOver } = useInterestExplorationStepHook(selectedLocale);
  return (
    <>
      <FeatureNavigation goBack={handleBack} startOver={handleStartOver} />
      <Box
        data-testid="interest-exploration-container"
        aria-label={intl.formatMessage({ id: 'personality-quiz.level2.title' })}
        data-tracking-id="interest-exploration-step"
      >
        <Box className={classes.root}>
          <Typography component="h2" variant="h2" data-testid="main-title" className={classes.mainTitle}>
            “<FormattedMessage id="personality-quiz.level2.title" />”
          </Typography>

          <Typography variant="body1" className={classes.description} data-testid="exploration-description">
            <FormattedMessage id="personality-quiz.level2.description" />
          </Typography>

          <Typography component="h2" variant="h2" data-testid="main-title" className={classes.mainTitle}>
            {questionData[0]?.description}
          </Typography>

          <Box className={classes.cardsGrid}>
            {questionData[0]?.options?.map((item) => (
              <Box key={item.id} className={classes.cardWrapper}>
                <StackedCard
                  description={item.description}
                  buttonText={intl.formatMessage({ id: 'personality-quiz.level2.option.buttonText' })}
                  imageUrl={item.imageUrl}
                  onClick={() => handleNext(Constant.RIASEC_MAP[item.value], item.description)}
                />
              </Box>
            ))}
          </Box>
          <Box py={4}>
            <Divider />
          </Box>
          <InterestProfilerAction />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLocale: state.locale.locale,
});

export default connect(mapStateToProps)(withStyles(styles)(InterestExplorationStep));
