export default (theme) => ({
  root: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    margin: '0 16px',
    [theme.breakpoints.up('md')]: {
      paddingTop: 48,
      paddingBottom: 48,
      gap: 32,
      margin: '0 32px',
    },
  },
  mainTitle: {
    fontSize: theme.typography.t30.fontSize,
    ...theme.typography.bold,
    lineHeight: '38px',
    width: '100%',
    textTransform: 'capitalize',
    zIndex: 99,
    [theme.breakpoints.up('md')]: {
      lineHeight: '52px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
  },
  logo: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '180px',
      height: '180px',
    },
  },
  subtitle: {
    ...theme.typography.bold,
  },
  description: {
    marginBottom: 0,
  },
});
