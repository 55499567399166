import React, { useEffect } from 'react';

import { withAuth0 } from '@auth0/auth0-react';
import { getAuthSessionCorrelation } from '../../auth/utils';

const LoginChangeLanguage = ({ match, auth0 }) => {
  const { locale } = match.params;

  useEffect(() => {
    auth0.loginWithRedirect({
      authorizationParams: { ui_locales: locale, 'ext-session-correlation': getAuthSessionCorrelation() },
    });
  }, []);

  /* TODO: add a loader or something more useful */
  return <div>Redirecting....</div>;
};

export default withAuth0(LoginChangeLanguage);
