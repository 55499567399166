export default (theme) => ({
  root: {
    paddingTop: 24,
    minWidth: 665,
    width: '100%',
  },
  container: {
    borderRadius: '16px',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    position: 'relative',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.greyScale[60]}`,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    color: theme.palette.navy.main,
  },
  description: {
    flex: 1,
    display: 'flex',
    color: theme.palette.greyScale[100],
  },
  subSection: {
    gap: 16,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    display: 'flex',
    height: '40px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
  },
});
