import { padding } from 'styled-system';

// styles.js
export default (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  callDate: {
    lineHeight: '21.333px',
    fontWeight: 'bold',
    color: theme.palette.greyScale[110],
    fontSize: '18px',
    marginBottom: '15px',
    marginLeft: '-20px',
  },
  noCalls: {
    padding: '24px',
    fontWeight: 400,
    color: theme.palette.greyScale[70],
  },
  callLogItem: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.greyed}`,
    borderRadius: '16px', // circular borders
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  callInfo: {
    marginBottom: theme.spacing(1),
  },
  callName: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    marginLeft: '34px',
    marginTop: '-2px',
  },
  callDetails: {
    color: theme.palette.primary.jetBlack,
    marginLeft: '34px',
    marginTop: '-8px',
    display: 'flex',
  },
  contact: {
    marginLeft: '34px',
    color: theme.palette.greyScale.grey80,
  },
  callDuration: {
    display: 'flex',
    justifyContent: 'end',
    color: theme.palette.greyScale[90],
  },
  callIcon: {
    marginBottom: '-30px',
  },
  callLogDuration: {
    marginLeft: '4px',
    color: theme.palette.greyScale[110],
  },
  callTypeLabel: {
    fontWeight: 'bold',
    marginRight: '16px',
  },
  callCompletedIcon: {
    color: theme.palette.green.main,
  },
  callMissedIcon: {
    color: theme.palette.greyScale.grey90,
  },
  callTerminatedIcon: {
    color: theme.alert.error,
  },
  timerIcon: {
    color: theme.palette.greyScale[110],
  },
});
