import { Box, Button, Grid, IconButton, useTheme, withStyles } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PrivateContactsCalling from '../private-contacts-calling/private-contacts-calling';
import HotlineContacts from '../hotlines-contacts/hotline-contacts';
import VoiceAuthenticationBanner from '../voice-authentication-banner/voice-authentication-banner';
import CallingModal from '../calling-modal/calling-modal';
import styles from './contacts-calling.style';
import useContactsCallingHook from './useContactsCallingHook';
import useHotlineContactsHook from '../hotlines-contacts/useHotlineContactsHook';
import { DialIcon, Utility, AccountCircleIcon, CallClosedIcon } from '../../../../shared';
import ErrorIcon from '@material-ui/icons/Error';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import StatusModal from '../status-modal/status-modal';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import ArchiveContactsModal from '../archive-contact-modal/archive-contact-modal';
import { store } from '../../../../core/store';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
import { AddIcon } from '@orijinworks/frontend-commons';
const ContactsCalling = (props) => {
  const {
    classes,
    handletabActive,
    TAB_VALUES,
    isClosed,
    handlePollingInterval,
    isVoiceAuthenticationEnabled,
    isVoiceIDRegistrationRequired,
    profileStatus,
  } = props;
  const meetingManager = useMeetingManager();
  const theme = useTheme();
  const { app } = store.getState();

  const { isFlagEnabled } = useUnleashHook(
    UnleashService.FLAGS.CALL_END_WHEN_CENTER_CLOSES,
    app.userDetail.userName,
    app.userDetail.userId
  );

  const {
    approvedContacts,
    privateContacts,
    isCallingModalOpen,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    timePerCall,
    formatPhoneNumber,
    isCallinProgress,
    setisCallingModalOpen,
    onCallClick,
    isErrorModalOpen,
    modalStatus,
    isDailyMinutesExceeded,
    handleErroModalClose,
    handleErroModalOpen,
    maxContactQuota,
    consumedContactQuota,
    archiveContacts,
    archiveEligibleContacts,
    isCallingEnabled,
  } = useContactsCallingHook(meetingManager, handlePollingInterval, isVoiceAuthenticationEnabled, isClosed, isFlagEnabled);
  const renderVoiceAuthenticationBanner = () => {
    return (
      <VoiceAuthenticationBanner
        showCreateButton={true}
        handletabActive={handletabActive}
        TAB_VALUES={TAB_VALUES}
        profileStatus={profileStatus}
      />
    );
  };
  const { hotlineContacts } = useHotlineContactsHook({ isClosed });
  const hotlinesDialButtonColor = isClosed ? theme.palette.greyScale.grey90 : theme.palette.primary.main;

  const intl = useIntl();
  return (
    <>
      {isVoiceIDRegistrationRequired && (
        <Grid container sm={12} className={classes.voiceAuthenticationBanner}>
          <Grid item sm={12}>
            {renderVoiceAuthenticationBanner()}
          </Grid>
        </Grid>
      )}
      {approvedContacts.length === 0 && (
        <>
          <Typography id="no-contacts-title" variant="h2" className={classes.approvedContactHeader}>
            <FormattedMessage id="no-contacts-heading" />
          </Typography>
          <Box className={classes.approvedContactContainer}>
            <Typography id="non-contacts-description" variant="body1" className={classes.approvedContactSubtext}>
              <FormattedMessage id="no-contacts-description" />
            </Typography>
            <Button
              variant="contained"
              className={classes.addContactButton}
              onClick={(event) => handletabActive(event, TAB_VALUES.Connections)}
            >
              <AddIcon size={24} color="#FFFFFF" />
              <Typography variant="body" className={classes.addContactButtonLabel}>
                <FormattedMessage id="addAContact" />
              </Typography>
            </Button>
          </Box>
        </>
      )}
      {approvedContacts.length > 0 && (
        <>
          <Typography variant="h2" className={classes.approvedContactHeader}>
            <FormattedMessage id="no-contacts-heading" />
          </Typography>
          <Typography id="approved-contacts" variant="body1" className={classes.contactsMonitoringText}>
            <FormattedMessage id="contacts-description" />
          </Typography>
          <Box height={24} />
          {approvedContacts.map((contact, index) => (
            <Box key={index} className={classes.contactContainer}>
              <Box>
                <AccountCircleIcon />
              </Box>
              <Box className={classes.contactInfoContainer}>
                <Typography id="contact-full-name" variant="bodyDecorFs20" className={classes.contactName}>
                  {Utility.getFullName(contact.firstName, contact.lastName)}
                </Typography>
                <Typography variant="overlineLarge" className={classes.contactRelationship}>
                  <FormattedMessage id={contact.relationshipType.toLowerCase()}>
                    {(text) => text.toUpperCase()}
                  </FormattedMessage>
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.contactNumber}
                  aria-label={formatPhoneNumber(contact.phoneNumber)}
                >
                  <FormattedMessage id="contact" />: {formatPhoneNumber(contact.phoneNumber)}
                </Typography>
              </Box>
              <IconButton
                aria-label={`Call ${Utility.getFullName(contact.firstName, contact.lastName)}`}
                id="dial-icon-button"
                className={classes.dialIconButton}
                onClick={() => onCallClick(contact)}
                disabled={isClosed || !isCallingEnabled}
              >
                {isClosed || !isCallingEnabled ? <CallClosedIcon /> : <DialIcon fill="#0F48B8" />}
              </IconButton>
            </Box>
          ))}
        </>
      )}

      {hotlineContacts?.length > 0 && (
        <HotlineContacts
          hotlineContacts={hotlineContacts}
          isClosed={isClosed}
          dialButtonColor={hotlinesDialButtonColor}
          handlePollingInterval={handlePollingInterval}
        />
      )}
      {privateContacts?.length > 0 && (
        <PrivateContactsCalling isClosed={isClosed} privateContacts={privateContacts} onCallClick={onCallClick} />
      )}

      <Box className={classes.footerContainer}>
        <Box className={classes.ErrorIconContainer}>
          <ErrorIcon className={classes.ErrorIcon} />
        </Box>
        <Box className={classes.ErrorTextContainer}>
          <Typography variant="body1" className={classes.ErrorText}>
            <FormattedMessage id="contacts-disclaimer" />
          </Typography>
        </Box>
      </Box>

      <CallingModal
        isCallingModalOpen={isCallingModalOpen}
        setisCallingModalOpen={setisCallingModalOpen}
        setonClose={() => {}}
        selectedContact={selectedContact}
        transactionId={transactionId}
        meetingId={meetingId}
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        timePerCall={timePerCall}
        isCallinProgress={isCallinProgress}
      />

      <StatusModal
        status={modalStatus}
        description={isDailyMinutesExceeded ? <FormattedMessage id="dailyMinutesLimitExceededError" /> : ''}
        handleStatusModalOpen={handleErroModalOpen}
        handleStatusModalClose={handleErroModalClose}
        isStatusModalOpen={isErrorModalOpen}
      />
      {archiveEligibleContacts.length > 0 && (
        <ArchiveContactsModal
          open={true}
          onClose={() => {}}
          onArchive={archiveContacts}
          maxContactQuota={maxContactQuota}
          consumedContactQuota={consumedContactQuota}
          contacts={archiveEligibleContacts}
        />
      )}
    </>
  );
};
export default withStyles(styles)(ContactsCalling);
