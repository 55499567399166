import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, IconButton, Typography, withStyles } from '@material-ui/core';
import styles from './message-editor.style';
import { EyeClosedIcon, EyeOpenIcon, PaperPlaneIcon, CircleExclamationSolid } from '../../icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Editor } from 'draft-js';
import 'draft-js/dist/Draft.css';
import useMessageEditorHook from './useMessageEditorHook';

const MessageEditor = (props) => {
  const { classes, placeholder, disableEditor, messageRelationshipStatus } = props;
  const {
    editorState,
    minCharLimit,
    maxCharLimit,
    charLimitError,
    isMessageSending,
    blurText,
    editorRef,
    handleEditorChange,
    handleKeyCommand,
    handleReturn,
    handleBeforeInput,
    handlePastedText,
    shouldDisableEditor,
    shouldDisableSendButton,
    handleSendButtonClick,
    focusEditor,
    toggleBlur,
    showBlurToggle,
  } = useMessageEditorHook(props);
  const intl = useIntl();

  return (
    <Box id="message-editor-root" className={[classes.root, disableEditor ? classes.disableEditor : ''].join(' ')}>
      <Box className={classes.mainContainer}>
        <Box
          id="message-editor-container"
          onClick={focusEditor}
          className={[
            classes.editorContainer,
            shouldDisableEditor() ? classes.disabledEditor : '',
            blurText ? classes.blurEditorContent : '',
          ].join(' ')}
        >
          {!messageRelationshipStatus && (
            <Box className={classes.noRelationshipMessage}>
              <IconButton className={classes.noRelationshipIcon}>
                <CircleExclamationSolid />
              </IconButton>
              <FormattedMessage id="relationshipBlockedMsg" />
            </Box>
          )}
          {messageRelationshipStatus && (
            <Editor
              placeholder={<FormattedMessage id={placeholder} />}
              editorState={editorState}
              onChange={handleEditorChange}
              handleKeyCommand={handleKeyCommand}
              handleReturn={handleReturn}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              readOnly={shouldDisableEditor()}
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              ref={editorRef}
              ariaLabel={intl.formatMessage({ id: placeholder })}
            />
          )}
        </Box>
        <Box className={classes.actionContainer}>
          <Box>
            {showBlurToggle() && (
              <Box className={classes.toggleBlurBox}>
                <IconButton
                  size="small"
                  className={classes.toggleBlurButton}
                  onClick={toggleBlur}
                  aria-label={intl.formatMessage({ id: 'toggleBlur' })}
                >
                  {blurText ? <EyeClosedIcon /> : <EyeOpenIcon />}
                </IconButton>
              </Box>
            )}
          </Box>
          <Button
            id="message-editor-send-button"
            variant="contained"
            color="primary"
            className={classes.sendButton}
            startIcon={
              isMessageSending ? (
                <CircularProgress className={classes.loader} size={15} />
              ) : (
                <PaperPlaneIcon width={18} height={16} />
              )
            }
            disabled={shouldDisableSendButton()}
            classes={{
              disabled: classes.disabledButton,
            }}
            onClick={handleSendButtonClick}
            aria-label={intl.formatMessage({ id: 'send' })}
          >
            <FormattedMessage id="send" />
          </Button>
        </Box>
      </Box>
      <Box className={classes.helperContainer}>
        <Typography
          id="message-editor-char-limit"
          className={[classes.helperText, charLimitError ? classes.errorText : ''].join(' ')}
        >
          <FormattedMessage
            id="characterLimit"
            values={{
              min: minCharLimit,
              max: maxCharLimit,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

MessageEditor.defaultProps = {
  disableSendButton: false,
  placeholder: 'typeYourMessage',
};

MessageEditor.propTypes = {
  disableSendButton: PropTypes.bool,
  characterLimit: PropTypes.exact({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  onSend: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disableEditor: PropTypes.bool,
  showBlurTextOption: PropTypes.bool,
};

export default withStyles(styles)(MessageEditor);
