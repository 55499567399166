import { Utility } from '../../../shared/services/utility/utility';

export class UnleashService {
  static CONFIG = {
    url: Utility.getEnv('REACT_APP_UNLEASH_URL'),
    clientKey: Utility.getEnv('REACT_APP_UNLEASH_CLIENT_KEY'),
    refreshInterval: Utility.getEnv('REACT_APP_UNLEASH_REFRESH_INTERVAL'),
    appName: Utility.getEnv('REACT_APP_UNLEASH_APP_NAME'),
    environment: Utility.getEnv('REACT_APP_UNLEASH_ENVIRONMENT'),
  };
  static FLAGS = {
    USE_V2_THREADS: 'useV2Threads',
    ALLOW_LINE_BREAKS: 'allowLineBreaks',
    TRANSCRIPT_V2: 'transcriptV2',
    TOGGLE_MESSAGE_VISIBILITY: 'toggleMessageVisibility',
    NEW_PROGRAM_CATALOG_DESIGN: 'newProgramCatalogDesign',
    PROGRESS_TRACKING_USING_AWARDS_API: 'progressTrackingAwards',
    CAREER_DIRECTORY_UPDATE: 'careerDirectoryUpdate',
    LEFT_NAV_UPDATE: 'leftNavUpdate',
    BREADCRUMB_NAVIGATION: 'breadcrumbNavigation',
    EXPLORE_PAGE_REDESIGN: 'explorePageRedesign',
    FEATURED_CONTENT_V2: 'FeaturedContentV2',
    VOICE_GROUP_SCHEDULE: 'learnerVoiceGroupSchedule',
    EMBED_DESMOS_CALCULATOR: 'embedDesmosCalculator',
    REMAINING_TOKEN_BALANCE: 'remainingTokenBalance',
    USE_OKTA_AUTH0: 'useOktaAuth0',
    ARCHIVE_UNARCHIVE_CONTACTS: 'archiveUnarchiveContacts',
    TRANSLATE_EXPLORE: 'translateExploreModule',
    LAZY_LOAD_ANNOUCEMENTS: 'lazyLoadAnnouncement',
    REDESIGN_PROGRAM_CATALOG: 'redesignProgramCatalog',
    PROGRAM_RECOMMENDATION: 'programRecommendation',
    MARK_MESSAGES_ANNOUNCEMENTS_READ: 'markMessagesAnnouncementsRead',
    ENTERTAINMENT_CENTER_ADS_POC: 'entertainmentCenterAdsPOC',
    ENABLE_CAREER_COMPASS_URL_STRUCTURE: 'enableCareerCompassUrlStructure',
    CALL_END_WHEN_CENTER_CLOSES: 'callEndsWhenCenterCloses',
    ENROLL_PROGRAMS_FROM_EXPLORE: 'enrollProgramsFromExplore',
    ENABLE_CAREER_COMPASS_PROGRESS: 'enableCareerCompassProgress',
    ENABLE_CAREER_INTEREST_QUIZ: 'enableCareerInterestQuiz',
    FOOTER: 'footerComponent',
  };
}
