import React from 'react';
import { Box } from '@material-ui/core';
import { ResumeBuilder as ResumeTool } from '@orijinworks/frontend-commons';
import { useIntl } from 'react-intl';
import useResumeBuilderHook from './useResumeBuilderHook';
import { useDocumentTitleHook } from '../../../../shared';
import { Permission } from '../../../../../core';
import { connect } from 'react-redux';

const ResumeBuilder = (props) => {
  const intl = useIntl();
  const { resumeData, statesOptions, degreeOptions, skillsOptions, onSave, onPreview } = useResumeBuilderHook(props);
  useDocumentTitleHook('page.title.resume-builder');

  return (
    <Permission>
      <Box>
        {resumeData && (
          <ResumeTool
            personalInfo={resumeData.personalInfo}
            workExperiences={resumeData.workExperiences}
            educations={resumeData.educations}
            achievements={resumeData.achievements}
            skills={resumeData.skills}
            stateOptions={statesOptions}
            degreeOptions={degreeOptions}
            skillsOptions={skillsOptions}
            onSave={onSave}
            showIntroductionComponent
            introductionProps={{
              title: intl.formatMessage({ id: 'resume-builder.introduction.title' }),
              description: intl.formatMessage({ id: 'resume-builder.introduction.description' }),
              instructions: intl.formatMessage({ id: 'resume-builder.introduction.instructions' }),
            }}
            personInformationProps={{
              personalInformationTitle: intl.formatMessage({ id: 'resume-builder.personal-information.heading' }),
              firstNameLabel: intl.formatMessage({ id: 'resume-builder.personal-information-first-name' }),
              lastNameLabel: intl.formatMessage({ id: 'resume-builder.personal-information-last-name' }),
              personalInformationLocation: intl.formatMessage({ id: 'resume-builder.personal-location.heading' }),
              cityLabel: intl.formatMessage({ id: 'resume-builder.location-city' }),
              stateLabel: intl.formatMessage({ id: 'resume-builder.location-state' }),
              contactInformation: intl.formatMessage({ id: 'resume-builder.personal-contact-information' }),
              phoneNumberLabel: intl.formatMessage({ id: 'resume-builder.personal-contact-information-phone' }),
              emailLabel: intl.formatMessage({ id: 'resume-builder.personal-contact-information-email' }),
              aboutMeLabel: intl.formatMessage({ id: 'resume-builder.personal-about-you.heading' }),
              aboutMeDescriptionLabel: intl.formatMessage({ id: 'resume-builder.personal-about-you-description' }),
              aboutMeIntroductionLabel: intl.formatMessage({ id: 'resume-builder.personal-about-you-introduction' }),
              characterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
              saveLabel: intl.formatMessage({ id: 'save' }),
              cancelLabel: intl.formatMessage({ id: 'cancel' }),
            }}
            showPreviewComponent
            previewProps={{
              title: intl.formatMessage({ id: 'resume-builder.preview.title' }),
              description: intl.formatMessage({ id: 'resume-builder.preview.description' }),
              buttonText: intl.formatMessage({ id: 'resume-builder.preview.button-text' }),
              onPreview,
            }}
            snackbarPosition={{ vertical: 'bottom', horizontal: 'center' }}
            allowReordering
            workExperienceProps={{
              labels: {
                reorganizeText: intl.formatMessage({ id: 'resume-builder.reorganize' }),
                workExperienceTitle: intl.formatMessage({ id: 'resume-builder.work-experience.heading' }),
                addWorkExperienceLabel: intl.formatMessage({ id: 'resume-builder.work-experience.add-button' }),
                characterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                saveLabel: intl.formatMessage({ id: 'save' }),
                cancelLabel: intl.formatMessage({ id: 'cancel' }),
                jobTitleLabel: intl.formatMessage({ id: 'resume-builder.work-experience.job-title' }),
                companyLabel: intl.formatMessage({ id: 'resume-builder.work-experience.company' }),
                locationLabel: intl.formatMessage({ id: 'resume-builder.work-experience.location' }),
                cityLabel: intl.formatMessage({ id: 'resume-builder.location-city' }),
                stateLabel: intl.formatMessage({ id: 'resume-builder.location-state' }),
                startDateLabel: intl.formatMessage({ id: 'resume-builder.work-experience.start-date' }),
                endDateLabel: intl.formatMessage({ id: 'resume-builder.work-experience.end-date' }),
                monthLabel: intl.formatMessage({ id: 'resume-builder.work-experience.start-date-month' }),
                yearLabel: intl.formatMessage({ id: 'resume-builder.work-experience.start-date-year' }),
                monthOptions: [
                  intl.formatMessage({ id: 'january' }),
                  intl.formatMessage({ id: 'february' }),
                  intl.formatMessage({ id: 'march' }),
                  intl.formatMessage({ id: 'april' }),
                  intl.formatMessage({ id: 'may' }),
                  intl.formatMessage({ id: 'june' }),
                  intl.formatMessage({ id: 'july' }),
                  intl.formatMessage({ id: 'august' }),
                  intl.formatMessage({ id: 'september' }),
                  intl.formatMessage({ id: 'october' }),
                  intl.formatMessage({ id: 'november' }),
                  intl.formatMessage({ id: 'december' }),
                ],
                currentlyWorkHereLabel: intl.formatMessage({ id: 'resume-builder.work-experience.currently-working' }),
                jobDescriptionLabel: intl.formatMessage({ id: 'resume-builder.work-experience.job-description' }),
                jobTitleCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                companyCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                workExperienceCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
              },
            }}
            achievementsProps={{
              labels: {
                reorganizeText: intl.formatMessage({ id: 'resume-builder.reorganize' }),
                achievementsTitle: intl.formatMessage({ id: 'resume-builder.achievements.heading' }),
                addAchievementLabel: intl.formatMessage({ id: 'resume-builder.achievements.add-button' }),
                certificateNameLabel: intl.formatMessage({ id: 'resume-builder.achievements.name' }),
                organizationLabel: intl.formatMessage({ id: 'resume-builder.achievements.issuing-organization' }),
                issueDateLabel: intl.formatMessage({ id: 'resume-builder.achievements.issue-date' }),
                descriptionLabel: intl.formatMessage({ id: 'resume-builder.achievements.description' }),
                descriptionHelperText: intl.formatMessage({ id: 'resume-builder.achievements.description-question' }),
                certificateNameCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                descriptionCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                organizationCharacterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                monthLabel: intl.formatMessage({ id: 'resume-builder.achievements.issue-date-month' }),
                yearLabel: intl.formatMessage({ id: 'resume-builder.achievements.issue-date-year' }),
                saveLabel: intl.formatMessage({ id: 'save' }),
                cancelLabel: intl.formatMessage({ id: 'cancel' }),
                monthOptions: [
                  intl.formatMessage({ id: 'january' }),
                  intl.formatMessage({ id: 'february' }),
                  intl.formatMessage({ id: 'march' }),
                  intl.formatMessage({ id: 'april' }),
                  intl.formatMessage({ id: 'may' }),
                  intl.formatMessage({ id: 'june' }),
                  intl.formatMessage({ id: 'july' }),
                  intl.formatMessage({ id: 'august' }),
                  intl.formatMessage({ id: 'september' }),
                  intl.formatMessage({ id: 'october' }),
                  intl.formatMessage({ id: 'november' }),
                  intl.formatMessage({ id: 'december' }),
                ],
              },
            }}
            educationProps={{
              labels: {
                reorganizeText: intl.formatMessage({ id: 'resume-builder.reorganize' }),
                educationTitle: intl.formatMessage({ id: 'resume-builder.education.heading' }),
                addEducationLabel: intl.formatMessage({ id: 'resume-builder.education.add-button' }),
                degreeLabel: intl.formatMessage({ id: 'resume-builder.education.degree' }),
                fieldOfStudyLabel: intl.formatMessage({ id: 'resume-builder.education.field-of-study' }),
                schoolLabel: intl.formatMessage({ id: 'resume-builder.education.school-name' }),
                graduationYearLabel: intl.formatMessage({ id: 'resume-builder.education.graduation-year' }),
                characterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                saveLabel: intl.formatMessage({ id: 'save' }),
                cancelLabel: intl.formatMessage({ id: 'cancel' }),
              },
            }}
            skillProps={{
              labels: {
                reorganizeText: intl.formatMessage({ id: 'resume-builder.reorganize' }),
                skillsTitle: intl.formatMessage({ id: 'resume-builder.skills.heading' }),
                addSkillLabel: intl.formatMessage({ id: 'resume-builder.skills.add-button' }),
                skillLabel: intl.formatMessage({ id: 'resume-builder.skills.skill' }),
                characterLimitLabel: intl.formatMessage({ id: 'resume-builder.character-limit' }),
                saveLabel: intl.formatMessage({ id: 'save' }),
                cancelLabel: intl.formatMessage({ id: 'cancel' }),
              },
            }}
            errorMessages={{
              REQUIRED_FIELD: intl.formatMessage({ id: 'resume-builder.error.required-field' }),
              PHONE_NUMBER: intl.formatMessage({ id: 'resume-builder.error.phone-number' }),
              EMAIL: intl.formatMessage({ id: 'resume-builder.error.email' }),
              INVALID_YEAR: intl.formatMessage({ id: 'resume-builder.error.invalid-year' }),
              INVALID_DATE: intl.formatMessage({ id: 'resume-builder.error.invalid-date' }),
              SMALLER_END_DATE: intl.formatMessage({ id: 'resume-builder.error.smaller-end-date' }),
              START_DATE_GREATER: intl.formatMessage({ id: 'resume-builder.error.start-date-greater' }),
              REMOVE_END_DATE: intl.formatMessage({ id: 'resume-builder.error.remove-end-date' }),
              INVALID_DATE_FUTURE: intl.formatMessage({ id: 'resume-builder.error.invalid-date-future' }),
              SKILL: intl.formatMessage({ id: 'resume-builder.error.skill-limit' }),
              SKILL_ALREADY_EXISTS: intl.formatMessage({ id: 'resume-builder.error.skill-already-exists' }),
              API_FAILED: intl.formatMessage({ id: 'resume-builder.error.api-failed' }),
              EDUCATION: intl.formatMessage({ id: 'resume-builder.error.limit-reached.education' }),
              WORK_EXPERIENCE: intl.formatMessage({ id: 'resume-builder.error.limit-reached.work-experience' }),
              CERTIFICATES_AND_ACHIEVEMENTS: intl.formatMessage({
                id: 'resume-builder.error.limit-reached.certificates-and-achievements',
              }),
              SKILLS: intl.formatMessage({ id: 'resume-builder.error.limit-reached.skills' }),
            }}
          />
        )}
      </Box>
    </Permission>
  );
};

const mapStateToProps = ({ app }) => ({
  userDetail: app.userDetail,
});

export default connect(mapStateToProps)(ResumeBuilder);
