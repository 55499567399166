export default (theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.greyScale[60]}`,
  },
  image: {
    width: '100%',
    borderRadius: '16px 16px 0 0',
    objectFit: 'cover no-repeat',
    backgroundColor: theme.palette.grey[200],
    alignself: 'stretch',
  },
  content: {
    padding: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    minHeight: '48px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    borderRadius: 100,
    textTransform: 'none',
    padding: '8px 24px',
    backgroundColor: theme.palette.accentTeal.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.accentTeal.main,
    },
  },
});
