import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './core';
import { Tracker } from './core/services/tracker/tracker';
import { ScrollToTop } from './shared';
import { ThemeProvider } from './v2/core';
import { initRUM } from './v2/core/services/rum/rum.service';

// locales We will change it dynamically in future
ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.pageview(window.location.pathname + window.location.search);
const _tracker = new Tracker(true);
_tracker.append(window.location.pathname + window.location.search);
initRUM();

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
