import React from 'react';

const AttorneyIcon = (props) => {
  const { size } = props;
  return (
    <svg
      role="img"
      aria-hidden="true"
      width={size}
      height={size}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 0C40.3594 0 52 11.6406 52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0Z"
        fill="#0F2C54"
      />
      <path
        d="M16.8076 34.5868H26.8076C27.3576 34.5868 27.8076 35.0368 27.8076 35.5868C27.8076 36.1368 27.3576 36.5868 26.8076 36.5868H16.8076C16.2576 36.5868 15.8076 36.1368 15.8076 35.5868C15.8076 35.0368 16.2576 34.5868 16.8076 34.5868ZM20.0476 21.6568L22.8776 18.8268L35.6076 31.5568C36.3876 32.3368 36.3876 33.6068 35.6076 34.3868C34.8276 35.1668 33.5576 35.1668 32.7776 34.3868L20.0476 21.6568ZM28.5376 15.9968L31.3676 18.8268C32.1476 19.6068 32.1476 20.8768 31.3676 21.6568L29.9476 23.0768L24.2976 17.4168L25.7076 16.0068C26.4876 15.2168 27.7576 15.2168 28.5376 15.9968ZM18.6376 23.0668L24.2976 28.7268L22.8876 30.1368C22.1076 30.9168 20.8376 30.9168 20.0576 30.1368L17.2276 27.3068C16.4476 26.5268 16.4476 25.2568 17.2276 24.4768L18.6376 23.0668Z"
        fill="#F8F9FC"
      />
    </svg>
  );
};
AttorneyIcon.defaultProps = {
  size: 52,
};

export default AttorneyIcon;
