export default (theme) => ({
  questionTitle: {
    color: theme.palette.textGrey,
    fontSize: theme.typography.t30.fontSize,
    ...theme.typography.bold,
    lineHeight: '38px',
    width: '100%',
    textTransform: 'capitalize',
  },
  questionDescription: {
    color: theme.palette.textGrey,
    fontSize: theme.typography.t16.fontSize,
    lineHeight: '24px',
    width: '100%',
    textTransform: 'none',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 32,
    padding: '0 24px',
    marginBottom: 32,
  },
});
