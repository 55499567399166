export default (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  mainTitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.greyScale[110],
    fontWeight: '800',
    textTransform: 'none',
    lineHeight: 'normal',
  },
  description: {
    marginBottom: theme.spacing(4),
    color: theme.palette.greyScale[110],
    width: '100%',
  },
  cardsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '0 auto 20px',
    columnGap: '24px',
    rowGap: '10px',
    [theme.breakpoints.up('md')]: {
      rowGap: '12px',
    },
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'center',
  },
});
