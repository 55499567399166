import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.yellow.pale,
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.navy.main,
    marginBottom: ({ interestProfileArea }) => theme.spacing(interestProfileArea ? 1 : 2),
    ...theme.typography.bold,
  },
  description: {
    color: theme.palette.navy.main,
    marginBottom: theme.spacing(3),
  },
  interestProfiler: {
    display: 'inline',
    fontWeight: '500',
    fontSize: '18px',
    marginLeft: theme.spacing(1),
    color: theme.palette.orange.main,
    textTransform: 'uppercase',
    letterSpacing: '1.8px',
    fontFamily: 'Rubik',
  },
  button: {
    borderRadius: 100,
    textTransform: 'none',
    border: ({ interestProfileArea }) => (interestProfileArea ? 'none' : `1px solid ${theme.palette.accentTeal.main}`),
    backgroundColor: ({ interestProfileArea }) => (interestProfileArea ? theme.palette.accentTeal.main : 'transparent'),
    color: ({ interestProfileArea }) => (interestProfileArea ? theme.palette.common.white : theme.palette.accentTeal.main),
    '&:hover': {
      backgroundColor: ({ interestProfileArea }) => (interestProfileArea ? theme.palette.accentTeal.dark : 'transparent'),
      border: ({ interestProfileArea }) => (interestProfileArea ? 'none' : `2px solid ${theme.palette.accentTeal.dark}`),
    },
  },
}));
