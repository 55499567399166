import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Box, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './career-cluster-exploration-step.styles';
import StackedCard from '../stacked-card/stacked-card';
import useCareerClusterExplorationStepHook from './useCareerClusterExplorationStepHook';
import FeatureNavigation from '../feature-navigation/feature-navigation';
import NotFound from '../../../../../shared/components/not-found/not-found';

const CareerClusterExplorationStep = ({ classes, selectedLocale, match }) => {
  const intl = useIntl();
  const location = useLocation();
  const riasecCode = match?.params?.riasecCode;

  const { questionData, handleNext, handleBack, handleStartOver } = useCareerClusterExplorationStepHook(
    selectedLocale,
    riasecCode
  );

  const currentQuestion = questionData[0];

  if (!riasecCode) {
    return <NotFound />;
  }

  return (
    <>
      <FeatureNavigation goBack={handleBack} startOver={handleStartOver} />
      <Box data-testid="career-cluster-exploration-container">
        <Box className={classes.root}>
          <Typography component="h2" variant="h2" data-testid="main-title" className={classes.mainTitle}>
            {location?.state?.description ? `“${location.state.description}..”` : `“${riasecCode.toUpperCase()}...”`}
          </Typography>

          <Typography variant="body1" className={classes.helperText} data-testid="helper-text">
            {currentQuestion?.helperText}
          </Typography>

          <Typography component="h2" variant="h2" data-testid="question-description" className={classes.mainTitle}>
            {currentQuestion?.description}
          </Typography>

          <Box className={classes.cardsGrid}>
            {currentQuestion?.options
              ?.filter((option) => !option.hidden)
              .map((item) => (
                <Box key={item.id} className={classes.cardWrapper}>
                  <StackedCard
                    description={item.description}
                    buttonText={intl.formatMessage({ id: 'personality-quiz.level2.option.buttonText' })}
                    imageUrl={item.imageUrl}
                    onClick={() => handleNext(item.value)}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLocale: state.locale.locale,
});

export default connect(mapStateToProps)(withStyles(styles)(CareerClusterExplorationStep));
