import { useEffect, useState } from 'react';
import { getCallHistoryLogsForLearner } from '../../../../services';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';

const useCallHistoryLogsHook = () => {
  const [callHistoryLogs, setCallHistoryLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    fetchCallHistoryLogs();
  }, []);

  const formatDate = () => {
    const options = { month: 'short', day: 'numeric' };
    const today = new Date().toLocaleDateString('en-US', options).toLocaleUpperCase();
    const todayLable = intl.formatMessage({ id: 'today' });
    return `${todayLable}, ${today}`;
  };
  const formatTime = (utcTime, timeZone) => {
    const formattedDate = moment.utc(utcTime).tz(timeZone).format('hh:mm A');
    return formattedDate;
  };

  const formatPhoneNumber = (phoneNumber) => {
    const regex = /^\+1\d{10}$/;
    if (!regex.test(phoneNumber)) {
      throw new Error('Invalid phone number format');
    }
    const countryCode = phoneNumber.substring(0, 2);
    const areaCode = phoneNumber.substring(2, 5);
    const centralOfficeCode = phoneNumber.substring(5, 8);
    const lineNumber = phoneNumber.substring(8, 12);
    return `${countryCode} (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  };

  const fetchCallHistoryLogs = async () => {
    try {
      setIsLoading(true);
      const response = await getCallHistoryLogsForLearner();
      if (response.data) {
        setCallHistoryLogs(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching call history logs', error);
      setIsLoading(false);
    }
  };

  return {
    callHistoryLogs,
    isLoading,
    formatDate,
    formatTime,
    formatPhoneNumber,
  };
};

export default useCallHistoryLogsHook;
