import React from 'react';
import { Box, Icon, IconButton, Grid } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { Skeleton } from '@material-ui/lab';
import VoiceAuthenticationBanner from '../voice-authentication-banner/voice-authentication-banner';
import { Constant, NoiceCancellationIcon } from '../../../../shared';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useVoiceIdRegistrationHook } from './useVoiceIdRegistrationHook';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-id-registration.style';
import VoiceIdSubmitted from './components/voice-id-submitted/voice-id-submitted';
import VoiceRecording from './components/voice-recording/voice-recording';
import { FormattedMessage } from 'react-intl';

const VoiceIdRegistration = ({ classes, handletabActive, TAB_VALUES }) => {
  const { activePrompt, handleNextPrompt, handlePreviousPrompt, PROMPTS, profileStatus, loading, setProfileStatus } =
    useVoiceIdRegistrationHook();
  const renderVoiceAuthenticationBanner = () => {
    return (
      <VoiceAuthenticationBanner
        showCreateButton={false}
        handletabActive={handletabActive}
        TAB_VALUES={TAB_VALUES}
        profileStatus={profileStatus}
      />
    );
  };
  return (
    <>
      {profileStatus && profileStatus !== Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED && (
        <Grid container sm={12} spacing={3} className={classes.voiceAuthenticationBanner}>
          <Grid item sm={12}>
            {renderVoiceAuthenticationBanner()}
          </Grid>
        </Grid>
      )}
      {loading ? (
        <>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="100%" height={80} />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="75%" />
          <Skeleton variant="text" width="65%" />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </>
      ) : profileStatus === Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED ? (
        <VoiceIdSubmitted handletabActive={handletabActive} TAB_VALUES={TAB_VALUES} />
      ) : (
        <>
          <Box className={classes.container} data-testid="voice-id-container">
            <Typography variant="h2" className={classes.title} data-testid="title">
              <FormattedMessage id="recordingDirection" />
            </Typography>

            <Typography variant="body" className={classes.bodyText} data-testid="body-text">
              <FormattedMessage id="recordingNote" />
            </Typography>

            <Typography variant="body" className={classes.recordText} data-testid="record-text">
              <FormattedMessage id="recordingInstructions1" />
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="postion-your-face-text">
              <FormattedMessage id="recordingInstructions2" />
            </Typography>
            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-1">
              <FormattedMessage id="recordingInstructions3" />
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-2">
              <FormattedMessage id="recordingInstructions4" />
            </Typography>

            <Typography variant="body" className={classes.speakFreeText} data-testid="speak-free-text-3">
              <FormattedMessage id="recordingInstructions5" />
            </Typography>

            <Typography variant="overlineSmall" className={classes.overlineText} data-testid="overline-text">
              <FormattedMessage id="recordingTips" />
            </Typography>

            <Typography variant="body" className={classes.stepText} data-testid="step-text">
              <VolumeUpIcon className={classes.icon} />
              <FormattedMessage id="recordingTip1" />
            </Typography>

            <Typography variant="body" className={classes.quietLocationText} data-testid="quiet-location-text">
              <Icon className={classes.NoiceCancellationIcon}>
                <NoiceCancellationIcon />
              </Icon>
              <FormattedMessage id="recordingTip2" />
            </Typography>

            <Typography variant="body" className={classes.quietLocationText} data-testid="enunciate-text">
              <RecordVoiceOverIcon className={classes.icon} />
              <FormattedMessage id="recordingTip3" />
            </Typography>

            <Typography variant="body" className={classes.holdTabletText} data-testid="hold-tablet-text">
              <TabletMacIcon className={classes.icon} />
              <FormattedMessage id="recordingTip4" />
            </Typography>
          </Box>

          <Box className={classes.recordingContainer} data-testid="recording-container">
            <Typography variant="h3" className={classes.title} data-testid="recording-title">
              <FormattedMessage id="recordingDirections" />
            </Typography>

            <Typography variant="body1" className={classes.recordingContainerSubTitle} data-testid="recording-subtitle">
              <FormattedMessage id="recordingDirections1" />
            </Typography>

            <Typography variant="overlineSmall" className={classes.recordingContainerBody} data-testid="recording-body">
              <FormattedMessage id="recordingDirections2" />
            </Typography>

            <Box className={classes.promptsContainer} data-testid="prompts-container">
              <Typography variant="body" className={classes.promptText} data-testid="prompt-text">
                {PROMPTS[activePrompt]}
              </Typography>

              <Box className={classes.promptsIconsBox} data-testid="prompts-icons-box">
                <IconButton className={classes.promptsIconButton} onClick={handleNextPrompt}>
                  <KeyboardArrowLeftIcon className={classes.keyboardLeftIcon} data-testid="next-prompt-icon" />
                </IconButton>
                <IconButton className={classes.promptsIconLeftButton} onClick={handlePreviousPrompt}>
                  <KeyboardArrowRightIcon className={classes.keyboardRightIcon} data-testid="previous-prompt-icon" />
                </IconButton>
              </Box>
            </Box>
            <VoiceRecording setProfileStatus={setProfileStatus} />
          </Box>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(VoiceIdRegistration);
