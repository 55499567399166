import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.background,
  },
  mainContainer: {
    overflow: 'hidden',
    width: '100%',
    padding: '0px 35px 0px 100px',
    marginBottom: 20,
    zIndex: 99,
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    padding: '32px 0px 0px',
    minHeight: 'calc(100vh - 20px)',
    transition: 'all 1s',
    maxWidth: '100%',
  },
  layoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  minThresold: {
    minHeight: 120,
  },
  mainWrapper: {
    paddingTop: 80,
  },
  skipLink: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
  footer: {
    padding: '0px 15px',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderTop: '1px solid var(--grey-60)',
    color: theme.palette.cerulean.main,
    paddingLeft: 95,
    backgroundColor: theme.palette.primary.white,
    '& ul': {
      margin: 0,
      padding: 0,
    },
    '& a': {
      color: theme.palette.cerulean.main,
      textDecoration: 'none',
    },
    '& li': {
      listStyle: 'none',
      display: 'inline-block',
      padding: '0px 10px',
      borderRight: '1px solid ' + 'var(--grey-60)',
      margin: '10px 0px',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
}));
