export default (theme) => ({
  rootContainer: {
    width: '611px',
    height: 'auto',
    border: '1px solid #D8DCE0',
    borderRadius: '24px',
    color: '#FFFFFF',
  },
  container: {
    width: '427px',
    height: 'auto',
    padding: '24px 25px 24px 25px',
    gap: '32px',
    opacity: '0px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  textSection: {
    width: '377px',
    height: 'auto',
    gap: '24px',
    opacity: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    color: theme.palette.text.primary,
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
  },
  btnText: {
    textTransform: 'none !important',
    fontWeight: 'bold',
  },
  heading: {
    textAlign: 'center',
  },
  description: {
    fontSie: '16px',
    fontWeight: '400',
    letterSpacing: '0.02em',
    textAlign: 'center',
  },
  imageSection: {
    display: 'flex',
    justifyContent: 'center',
  },
});
