import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import styles from './stacked-card.styles';

const StackedCard = ({ classes, description, buttonText, imageUrl, onClick }) => {
  return (
    <Box className={classes.card} data-testid="stacked-card">
      <img src={imageUrl} alt="" className={classes.image} data-testid="card-image" />
      <Box className={classes.content}>
        <Typography variant="body1" className={classes.description} data-testid="card-description">
          {description}
        </Typography>
        <Button variant="contained" color="primary" className={classes.button} onClick={onClick} data-testid="card-button">
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

StackedCard.propTypes = {
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(StackedCard);
