import { AwsRum } from 'aws-rum-web';
import { getAuthProvider, getAuthSessionCorrelation, getUsername } from '../../auth/utils';
import { extractDeviceId } from '../../../shared/services/utility/utility';

/**
 * Variable to store the AWS RUM instance.
 */
let awsRum = null;

/**
 * Enum for RUM events.
 */
export const RUM_EVENTS_ENUM = {
  PORTAL_LAUNCH: 'learner-portal.launch',
  AUTH0_SDK_ERROR: 'learner-portal.auth0-sdk-error',
  AUTH_NEEDED: 'learner-portal.auth-needed',
  UNAUTHORIZED_LOGIN: 'learner-portal.unauthorized-login',
  AUTH_SESSION_FOUND: 'learner-portal.auth-session-found',
  LOGOUT_INITIATED: 'learner-portal.logout-initiated',
};

/**
 * Function to initialize RUM.
 */
export const initRUM = () => {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_RUM_ENDPOINT,
      telemetries: ['errors'],
      allowCookies: true,
      enableXRay: false,
      disableAutoPageView: true,
      sessionEventLimit: 0,
      sessionAttributes: {
        deviceId: extractDeviceId(),
      },
    };

    awsRum = new AwsRum(
      process.env.REACT_APP_RUM_APP_ID,
      process.env.REACT_APP_RUM_APP_VERSION,
      process.env.REACT_APP_RUM_APP_REGION,
      config
    );

    console.log('RUM initialized', awsRum);
  } catch (error) {
    console.error('Error initializing RUM', error);
  }
};

/**
 * Function to record custom RUM event.
 * User context (auth provider, username, trace id, device) is added to the event automatically.
 *
 * @param {string} event - The event name
 * @param {Object} data - The event data
 */
export const recordRUMEvent = (event, data = {}) => {
  try {
    if (awsRum) {
      const userContext = {
        authProvider: getAuthProvider(),
        username: getUsername() || '',
        traceId: getAuthSessionCorrelation(),
      };
      awsRum.recordEvent(event, {
        timestamp: new Date().toISOString(),
        ...userContext,
        ...data,
      });
    }
  } catch (error) {
    console.error('Error recording RUM event', error);
  }
};
