import React from 'react';
import { Box, Button, Link } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/core/styles';
import { VoiceChatIcon } from '../../../../shared';
import styles from './voice-authentication-banner.style';
import { FormattedMessage } from 'react-intl';
import CallCenterSchedullingModal from '../call-center-scheduling-modal/call-center-schedulling-modal';
import useVoiceAuthenticationBannerHook from './useVoiceAuthenticationBannerHook';
import useCallUsageBannerHook from '../../components/call-history/components/call-usage-banner/useCallUsageBannerHook';
import { Constant } from '../../../../shared';
import useScheduleWrapperHook from '../../../../shared/components/schedule-wrapper/useScheduleWrapperHook';

const VoiceAuthenticationBanner = ({ classes, showCreateButton, handletabActive, TAB_VALUES, profileStatus }) => {
  const { openModal, closeModal, callCenterSchedullingModalRef } = useVoiceAuthenticationBannerHook();
  const { groupSchedule, isGroupScheduleClosed } = useScheduleWrapperHook(Constant.FEATURES_ENUM.VOICE_CALLS_SCHEDULE);
  const { bannerType } = useCallUsageBannerHook();

  const isRerecord = profileStatus === 'RERECORD_PROFILE';

  const headerMessage = isRerecord
    ? { id: 'voiceAuthenticationBanner.rerecordHeader', defaultMessage: 'Please Re-record Your Voice Authentication' }
    : { id: 'voiceAuthenticationBanner.header', defaultMessage: 'Voice Authentication' };

  const descriptionMessage = isRerecord
    ? {
        id: 'voiceAuthenticationBanner.rerecordDescription',
        defaultMessage: 'Your voice ID will need to be re-recorded. Create a new voice ID before making calls.',
      }
    : {
        id: 'voiceAuthenticationBanner.description',
        defaultMessage:
          "Please complete your voice ID setup to use Voice Calls. You won't be able to make calls to contacts until this setup is finished.",
      };

  const createButtonMessage = isRerecord
    ? { id: 'voiceAuthenticationBanner.rerecordCreateVoiceId', defaultMessage: 'Create New Voice ID' }
    : { id: 'voiceAuthenticationBanner.createVoiceId', defaultMessage: 'Create Voice ID' };

  return (
    <Box className={`${classes.main} ${isRerecord ? classes.redBanner : ''}`}>
      <Box className={classes.headingSection}>
        <VoiceChatIcon width="32px" height="32px" fill={isRerecord ? '#9E162F' : '#0F48B8'} />
        <Typography variant="body1" className={`${classes.heading} ${isRerecord ? classes.redBannerHeading : ''}`}>
          <FormattedMessage {...headerMessage} />
        </Typography>
      </Box>

      <Box className={classes.textContent}>
        <Typography variant="body1" className={classes.bannerMessage}>
          <FormattedMessage {...descriptionMessage} />
        </Typography>
        <Typography variant="body1" className={classes.additionalMessage}>
          <FormattedMessage
            id="voiceAuthenticationBanner.additionalInfo"
            defaultMessage="You are free to make calls to privileged contacts and hotlines during facility scheduled times."
          />
          <Link component="button" type="button" className={classes.scheduleLink} onClick={openModal}>
            <FormattedMessage id="voiceAuthenticationBanner.viewSchedule" defaultMessage="View the full schedule." />
          </Link>
        </Typography>
        {showCreateButton && (
          <Button
            variant="contained"
            className={`${classes.createVoiceIdButton} ${isRerecord ? classes.createVoiceIdButtonSecondary : ''}`}
            onClick={(event) => handletabActive(event, TAB_VALUES.VoiceIdRegistration)}
          >
            <FormattedMessage {...createButtonMessage} />
          </Button>
        )}
      </Box>

      <CallCenterSchedullingModal
        ref={callCenterSchedullingModalRef}
        isOpen={false}
        onClose={closeModal}
        contactName=""
        onContinue={() => {}}
        schedule={groupSchedule}
        isClosed={isGroupScheduleClosed}
        isCallTimeLimitReached={bannerType === 'CALL_TIME_LIMIT_REACHED'}
      />
    </Box>
  );
};

export default withStyles(styles)(VoiceAuthenticationBanner);
