import React from 'react';
import { Box, withStyles, IconButton } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import ContactRelationshipIcons from '../contact-relationship-icons/contact-relationship-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './private-contacts-calling.style';
import { Utility } from '../../../../shared/services';
import { DialIcon, CallClosedIcon } from '../../../../shared';

const relationshipTypeLabel = {
  GENERAL_PRIVILEGED_CONTACT: 'GENERAL PRIVILEGED CONTACT',
  ATTORNEY: 'ATTORNEY',
  MEDICAL: 'MEDICAL',
};

const PrivateContactsCalling = (props) => {
  const { classes, isClosed } = props;
  const intl = useIntl();

  const formatPhoneNumber = (phoneNumber) => {
    const regex = /^\+1\d{10}$/;
    if (!regex.test(phoneNumber)) {
      throw new Error('Invalid phone number format');
    }
    const countryCode = phoneNumber.substring(0, 2);
    const areaCode = phoneNumber.substring(2, 5);
    const centralOfficeCode = phoneNumber.substring(5, 8);
    const lineNumber = phoneNumber.substring(8, 12);
    return `${countryCode} (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  };

  return (
    <>
      <Typography id="private-contacts-heading" variant="h2" className={classes.approvedContactHeader}>
        <FormattedMessage id="privlieged-contacts-heading" />
      </Typography>

      {props.privateContacts?.map((contact, index) => (
        <Box id={`private-contacts-${index}`} key={index} className={classes.contactContainer}>
          <Box>{contact?.relationshipType && <ContactRelationshipIcons relationshipType={contact.relationshipType} />}</Box>
          <Box className={classes.contactInfoContainer}>
            <Typography id={`name-${index}`} variant="body" className={classes.contactName}>
              {Utility.getFullName(contact.firstName, contact.lastName)}
            </Typography>
            <Typography id={`relationshipType-${index}`} variant="overline1" className={classes.contactRelationship}>
              <FormattedMessage id={contact.relationshipType.toLowerCase()}>{(text) => text.toUpperCase()}</FormattedMessage>
            </Typography>
            <Typography
              id={`contactNumber-${index}`}
              variant="body"
              className={classes.contactNumber}
              aria-label={formatPhoneNumber(contact.phoneNumber)}
            >
              <FormattedMessage id="contact" />: {formatPhoneNumber(contact.phoneNumber)}
            </Typography>
          </Box>
          <IconButton
            aria-label={`Call ${Utility.getFullName(contact.firstName, contact.lastName)}`}
            id={`callButton-${index}`}
            className={classes.dialIconButton}
            onClick={() => props.onCallClick(contact)}
            disabled={isClosed}
          >
            {props.isClosed ? <CallClosedIcon /> : <DialIcon fill="#0F48B8" />}
          </IconButton>
        </Box>
      ))}
    </>
  );
};

export default withStyles(styles)(PrivateContactsCalling);
