export default (theme) => ({
  foundationNote: {
    color: theme.palette.textGrey,
    ...theme.typography.bold,
  },
  foundationButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    borderRadius: '100px',
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
});
