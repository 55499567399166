import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, withStyles } from '@material-ui/core';
import styles from './option-box.styles';

const OptionBox = ({ classes, imageUrl, imageAlt, buttonText, onClick }) => {
  return (
    <Box className={classes.optionBox} data-testid="option-box">
      <img src={imageUrl} alt={imageAlt} className={classes.imagePlaceholder} data-testid="option-image" />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClick}
        data-testid="option-button"
        aria-label="option-button"
      >
        {buttonText}
      </Button>
    </Box>
  );
};

OptionBox.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(OptionBox);
