import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, ClickAwayListener, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import withUnleash from '../../../core/components/unleash/withUnleash';
import { SideMenu as SideMenuV2 } from '../../../v2/core/components';
import { SideMenu, Header, UnleashProvider } from '../../';
import { UnleashService } from '../../services/unleash/unleash';
import useStyles from './layout.style';
import { Loader } from '../../../shared/components';
import { Breadcrumbs } from '../../../v2/core/components';
import { Typography } from '@orijinworks/frontend-commons';
import moment from 'moment';

function Layout(props) {
  const classes = useStyles();
  const { unleashProps } = props;
  const intl = useIntl();
  const BASE_URL = window.location.origin;
  const [drawerOpen, setDrawerToggle] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerToggle(!drawerOpen);
  };
  const handleClickAway = () => {
    if (drawerOpen) {
      setDrawerToggle(false);
    }
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid className={classes.layoutWrapper}>
        <a href="#main-application" className={classes.skipLink}>
          {intl.formatMessage({ id: 'skipToMainContent' })}
        </a>
        <Header {...props} />
        <Box className={classes.mainWrapper}>
          {!props.isRequiredFormExist && (
            <Box component="div">
              <ClickAwayListener onClickAway={handleClickAway}>
                <SideMenuV2
                  drawer={{
                    open: drawerOpen,
                    handleDrawerToggle: handleDrawerToggle,
                  }}
                  {...props}
                />
              </ClickAwayListener>
            </Box>
          )}
          <Box className={classes.mainContainer} component="div" id="main-container">
            <Grid container direction="column">
              <Loader />
              <UnleashProvider
                flagName={UnleashService.FLAGS.BREADCRUMB_NAVIGATION}
                enabledRenderFn={() => <Breadcrumbs {...props} />}
              />
              <Box component="main" id="main-application" className={classes.content} tabindex="0">
                {props.children}
              </Box>
            </Grid>
          </Box>
          {unleashProps.isFlagEnabled() && (
            <Box
              id="footer"
              className={classes.footer}
              component="div"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography id="copyright-text">
                <a href={`${BASE_URL}/copyrights.html`} target="_blank">
                  <FormattedMessage id="footer.copyrights" />
                </a>
                <FormattedMessage
                  id="footer.copyrights.orijin"
                  values={{
                    year: moment().format('YYYY'),
                  }}
                />
              </Typography>
              <Box>
                <ul>
                  <li>
                    <Typography>
                      <a target="_blank" href={`${BASE_URL}/terms-of-service.html`}>
                        <FormattedMessage id="footer.terms-of-services" />
                      </a>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <a target="_blank" href={`${BASE_URL}/privacy-policy.html`}>
                        <FormattedMessage id="footer.privacy-policy" />
                      </a>
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </div>
  );
}

export default withUnleash(Layout, UnleashService.FLAGS.FOOTER);
