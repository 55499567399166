import { useEffect, useState } from 'react';
import { getCallHistoryUsageForLearner } from '../../../../services';
import { StatusCodes } from 'http-status-codes';
import { useIntl } from 'react-intl';

const useCallUsageBannerHook = () => {
  const [maxCallDurationPerCall, setMaxCallDurationPerCall] = useState(null);
  const [maxCallDurationPerDay, setMaxCallDurationPerDay] = useState(null);
  const [consumedCallDurationPerDay, setConsumedCallDurationPerDay] = useState(null);
  const [remainingCallDurationPerDay, setRemainingCallDurationPerDay] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerType, setBannerType] = useState(null);
  const [showFullSchedule, setShowFullSchedule] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    fetchCallUsage();
  }, []);

  const fetchCallUsage = async () => {
    try {
      setIsLoading(true);
      const response = await getCallHistoryUsageForLearner();
      if (response.status === StatusCodes.OK) {
        const { maxCallDurationPerCall, maxCallDurationPerDay, consumedCallDurationPerDay, remainingCallDurationPerDay } =
          response.data;
        setMaxCallDurationPerCall(maxCallDurationPerCall);
        setMaxCallDurationPerDay(maxCallDurationPerDay);
        determineCallTimeLimitAvailibilty(
          consumedCallDurationPerDay,
          remainingCallDurationPerDay,
          maxCallDurationPerDay,
          maxCallDurationPerCall
        );
      } else {
        console.error('Error fetching call usage', response);
      }
    } catch (error) {
      console.error('Error fetching call usage', error);
    } finally {
      setIsLoading(false);
    }
  };

  // NOT IN USE
  const isMinorDifference = (consumed, remaining) => {
    const THRESHOLD_VALUE = 60; // 60 seconds
    const MINUTE = 60; // 60 seconds
    return Math.abs(consumed - remaining) * MINUTE <= THRESHOLD_VALUE;
  };

  const determineCallTimeLimitAvailibilty = (consumed, remaining, maxCallDurationPerDay, maxCallDurationPerCall) => {
    const formattedConsumed = converTimeIntoMintuesAndSeconds(consumed);
    const formattedRemaining = converTimeIntoMintuesAndSeconds(remaining);
    const isLimitReached = Math.floor(remaining) <= 0;

    setConsumedCallDurationPerDay(
      isLimitReached ? converTimeIntoMintuesAndSeconds(maxCallDurationPerDay) : formattedConsumed
    );
    setRemainingCallDurationPerDay(formattedRemaining);
    setBannerType(isLimitReached ? 'CALL_TIME_LIMIT_REACHED' : 'CALL_TIME_LIMIT_NOT_REACHED');
  };

  const converTimeIntoMintuesAndSeconds = (duration) => {
    const minutes = Math.floor(duration);
    const seconds = Math.round((duration - minutes) * 60);
    let totalMinutes = 0;
    if (seconds >= 1 && seconds <= 59) totalMinutes = minutes + 1;
    else totalMinutes = minutes;

    return totalMinutes === 1
      ? `${totalMinutes} ${intl.formatMessage({ id: 'minute' })}`
      : `${totalMinutes} ${intl.formatMessage({ id: 'minutes' })}`;
  };
  const handleShowFullSchedule = () => {
    setShowFullSchedule((prev) => !prev);
  };

  const updateCallUsages = () => {
    fetchCallUsage();
  };

  return {
    maxCallDurationPerCall,
    maxCallDurationPerDay,
    consumedCallDurationPerDay,
    remainingCallDurationPerDay,
    isLoading,
    bannerType,
    handleShowFullSchedule,
    showFullSchedule,
    updateCallUsages,
  };
};

export default useCallUsageBannerHook;
