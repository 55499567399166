const styles = (theme) => ({
  main: {
    backgroundColor: '#F0F6FF',
    border: '1px solid var(--blue-main)',
    padding: '16px 20px',
    borderRadius: '12px',
    maxWidth: '700px',
    textAlign: 'left',
  },
  redBanner: {
    backgroundColor: '#FFECF0',
    border: '1px solid #9E162F',
  },
  headingSection: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: '16px',
  },
  heading: {
    fontWeight: '700 !important',
    marginLeft: '12px !important',
  },
  redBannerHeading: {
    textTransform: 'none',
  },
  textContent: {
    marginLeft: `calc(32px + ${theme.spacing(1.5)}px)`,
  },
  bannerMessage: {
    marginBottom: theme.spacing(1.5),
    color: '#333333',
    fontSize: '16px',
    letterSpacing: '2%',
    fontFamily: 'Inter',
  },
  additionalMessage: {
    marginBottom: theme.spacing(3),
    marginTop: '24px !important',
    color: '#333333',
    fontSize: '16px',
    letterSpacing: '2%',
    fontFamily: 'Inter',
  },
  scheduleLink: {
    marginRight: '4px',
    color: '#0078A2',
    fontWeight: '700',
    fontSize: '16px',
    cursor: 'pointer',
    fontFamily: 'Inter',
    letterSpacing: '2%',
  },
  createVoiceIdButton: {
    backgroundColor: 'var(--blue-main)',
    borderRadius: '100px',
    color: '#fff',
    textTransform: 'none',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontSize: '16px',
    boxShadow: 'none',
    letterSpacing: '2% !important',
    '&:hover': {
      backgroundColor: '#005bb5',
    },
    marginTop: '12px',
  },
  createVoiceIdButtonSecondary: {
    backgroundColor: '#E32D4E',
    '&:hover': {
      backgroundColor: '#E32D4E',
    },
    marginTop: '12px',
  },
});

export default styles;
