import { useCallback, useEffect, useState } from 'react';
import {
  StaffMessagingConstants,
  fetchAnnouncements,
  fetchContacts,
  fetchMessageThreads,
  getUnreadCount,
  markAnnouncementAsRead,
  transformAnnouncementThreads,
} from '../../services/staff-messaging.service';
import {
  setActiveCommunicationMode,
  setForceReloadMessageThreads,
  setMarkSelectedThreadAsRead,
  setSelectedContact,
  setSelectedThread,
  setUnreadAnnouncementCount,
  setUnreadMessagesCount,
  store,
  UnleashService,
} from '../../../../core';
import { Constant, Utility } from '../../../../shared/services';
import { MessagingService } from '../../../messaging';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
import { registerAction } from '../../../../core/services/socket/socket';

const useMessageSidebarHook = (props) => {
  const { staffMessaging, app, resetConversationView, isActiveModeMessages, isActiveModeAnnouncements } = props;
  const { selectedThread, selectedContact, forceReloadMessageThreads, markSelectedThreadAsRead } = staffMessaging;
  const { unreadMessagesCount: unreadMessageThreadsCount, announcementCount: unreadAnnouncementThreadsCount } = app;
  const selectedContactId = selectedContact?.contactId;
  const [contactsLoading, setContactsLoading] = useState(false);
  const [messageThreadsLoading, setMessageThreadsLoading] = useState(false);
  const [announcementThreadsLoading, setAnnouncementThreadsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [messageThreads, setMessageThreads] = useState([]);
  const [announcementThreads, setAnnouncementThreads] = useState([]);
  const [totalMessageThreads, setTotalMessageThreads] = useState(0);
  const [totalAnnouncementThreads, setTotalAnnouncementThreads] = useState(0);
  const [isLazyLoading, setLazyLoading] = useState(false);
  const [relationStatusChangedState, setRelationStatusChangedState] = useState('false');
  const { isFlagEnabled: isLazyLoadFlagEnabled } = useUnleashHook(
    UnleashService.FLAGS.LAZY_LOAD_ANNOUCEMENTS,
    app.userDetail.userName,
    app.userDetail.userId
  );

  const relationStatusChanged = (data) => {
    messageThreads.map((item) => {
      if (item.contactId === data.firstPersonKey || item.contactId === data.secondPersonKey) {
        item.messageRelationshipStatus = data.relationshipStatus;
      }
    });
    setMessageThreads(messageThreads);
    setRelationStatusChangedState(data.firstPersonKey + data.secondPersonKey + data.relationshipStatus);
    handleThreadClick(selectedThread);
  };

  useEffect(() => {
    if (!Constant.POLL_CONFIG.USE_POLLING) {
      registerAction({
        event: 'general_message_event: relationship',
        action: (data) => relationStatusChanged(data),
      });
    }
  }, [messageThreads]);

  const fetchSidebarData = useCallback(() => {
    getAllContacts();
    getAnnouncementThreads();
    getMessageThreads();
  }, []);

  useEffect(() => {
    setDataLoading();
    fetchSidebarData();
  }, [fetchSidebarData]);

  useEffect(() => {
    if (Constant.POLL_CONFIG.USE_POLLING) {
      const pollingInterval = setInterval(() => {
        fetchSidebarData();
      }, Constant.MESSAGE_POLL_INTERVAL);

      return () => {
        clearInterval(pollingInterval);
      };
    } else {
      onNewMessageEvent();
    }
  }, [fetchSidebarData]);

  useEffect(() => {
    if (Constant.POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
      //TODO: REMOVE THIS PIECE OF CODE ONCE DEPLOYED TO PRODUCTION
      let announcementPollingInterval;
      if (isLazyLoadFlagEnabled()) {
        getAnnouncementThreads();
        announcementPollingInterval = setInterval(() => {
          pollAnnouncement();
        }, Constant.ANNOUNCEMENT_POLL_INTERVAL);
      } else {
        announcementPollingInterval = setInterval(() => {
          getAnnouncementThreads();
        }, Constant.ANNOUNCEMENT_POLL_INTERVAL);
      }
      return () => {
        clearInterval(announcementPollingInterval);
      };
    } else {
      onNewAnnouncementListener();
    }
  }, []);

  const onNewAnnouncementCallback = ({ detail }) => {
    setAnnouncementThreads((announcementThreads) => [...transformAnnouncementThreads([detail]), ...announcementThreads]);
    setTotalAnnouncementThreads((count) => count + 1);
  };

  const onNewAnnouncementListener = () => {
    const announcementElement = document.querySelector(`#${MessagingService.ANNOUNCEMENT_ELEMENT}`);
    if (announcementElement) {
      announcementElement.addEventListener(MessagingService.NEW_ANNOUNCEMENT, onNewAnnouncementCallback, false);
    }
  };

  const selectContactThreadIfExists = useCallback(() => {
    const thread = messageThreads.find((item) => parseInt(item.contactId) === parseInt(selectedContactId));
    if (thread) {
      handleThreadClick(thread);
      store.dispatch(setSelectedContact(null));
    }
  }, [selectedContactId, messageThreads]);

  // This effect is used to fetch message thread when forceReloadMessageThreads is set to true.
  // This flag is true when a new message is sent from conversation view.
  useEffect(() => {
    if (forceReloadMessageThreads) {
      getMessageThreads(() => {
        selectContactThreadIfExists();
      });
      store.dispatch(setForceReloadMessageThreads(false));
    }
  }, [forceReloadMessageThreads, selectContactThreadIfExists]);

  // This effect is used to select thread of selected contact if thread exists.
  // This is to automatically select the existing thread when the user attempts
  // to send a new message to a contact with whom a thread already exists.
  useEffect(() => {
    if (selectedContact) {
      selectContactThreadIfExists();
    }
  }, [selectedContact, selectContactThreadIfExists]);

  const markThreadAsRead = useCallback(
    async (thread) => {
      try {
        if (isActiveModeAnnouncements()) {
          await markAnnouncementAsRead(thread.threadId);
          setAnnouncementThreads(() => resetUnreadMessages([...announcementThreads], thread.threadId));
          store.dispatch(setUnreadAnnouncementCount(unreadAnnouncementThreadsCount - 1));
        } else if (isActiveModeMessages()) {
          // mark messages thread as read API requires recent message id along with thread id.
          // Therefore, API call is implemented in useConversationHistoryHook.
          setMessageThreads(resetUnreadMessages([...messageThreads], thread.threadId));
          store.dispatch(setUnreadMessagesCount(unreadMessageThreadsCount - 1));
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      announcementThreads,
      isActiveModeAnnouncements,
      isActiveModeMessages,
      messageThreads,
      unreadAnnouncementThreadsCount,
      unreadMessageThreadsCount,
    ]
  );

  const updateSideBar = ({ detail }) => {
    const { threadId, body } = detail;
    setMessageThreads((messagesThread) => {
      const notifiedThreadIndex = messagesThread.findIndex((thread) => thread.threadId === threadId);
      if (notifiedThreadIndex >= 0) {
        messagesThread[notifiedThreadIndex] = {
          ...messagesThread[notifiedThreadIndex],
          lastMessage: {
            datetime: Utility.transformDateTime(new Date()),
            body: body.clearBody,
          },
          hasUnreadMessages: !isThreadSelected(messagesThread[notifiedThreadIndex]),
        };
      } else {
        fetchSidebarData();
      }
      return messagesThread;
    });
  };

  const onNewMessageEvent = () => {
    const messageElement = document.querySelector(`#${MessagingService.MESSAGE_ELEMENT}`);
    if (messageElement) {
      messageElement.addEventListener(MessagingService.NEW_MSG_EVENT, updateSideBar, false);
    }
  };

  // This effect is used to mark selected thread as read when markSelectedThreadAsRead is set to true.
  // This flag is set to true when user reads the messages or announcement details.
  useEffect(() => {
    if (markSelectedThreadAsRead) {
      markThreadAsRead(selectedThread);
      store.dispatch(setMarkSelectedThreadAsRead(false));
    }
  }, [markSelectedThreadAsRead, markThreadAsRead, selectedThread]);

  const setDataLoading = () => {
    setContactsLoading(true);
    setMessageThreadsLoading(true);
    setAnnouncementThreadsLoading(true);
  };

  const getAllContacts = async () => {
    try {
      const contacts = await fetchContacts('%25');
      setContacts(contacts);
      setContactsLoading(false);
    } catch (e) {
      console.log(e);
      setContactsLoading(false);
    }
  };

  const getMessageThreads = async (cb) => {
    try {
      const data = await fetchMessageThreads();
      setMessageThreads(data.threads);
      setTotalMessageThreads(data.totalThreads);
      setMessageThreadsLoading(false);
      if (cb) cb();
    } catch (e) {
      console.log(e);
      setMessageThreadsLoading(false);
    }
  };

  const pollAnnouncement = async () => {
    try {
      let previousAnnouncements = [];
      let newAnnouncements = [];
      const data = await fetchAnnouncements({
        limit: 5,
      });
      setAnnouncementThreads((prev) => {
        previousAnnouncements = prev; // USING THIS APPROACH SINCE announcementThreads IS NOT UPDATED WITH THE ACTUAL VALUE
        return prev;
      });
      newAnnouncements = data.threads.filter(
        (thread) => !previousAnnouncements.find((prevThead) => prevThead.threadId === thread.threadId)
      );
      setAnnouncementThreads((announcementThreads) => [...newAnnouncements, ...announcementThreads]);
      setTotalAnnouncementThreads((count) => count + newAnnouncements.length);
    } catch (e) {
      console.log(e);
      setAnnouncementThreadsLoading(false);
    }
  };

  const getAnnouncementThreads = async () => {
    try {
      const data = await fetchAnnouncements({});
      setAnnouncementThreads(() => data.threads);
      setTotalAnnouncementThreads(data.totalThreads);
      setAnnouncementThreadsLoading(false);
    } catch (e) {
      console.log(e);
      setAnnouncementThreadsLoading(false);
    }
  };

  const loadMoreThreads = () => {
    if (isActiveModeMessages()) {
      // Currently fetch message threads API is not paginated,
      // it returns all threads in one go.
      // When pagination is implemented,
      // use this code block to load more threads.
      // Infinite scroll is already implemented.
    } else if (isActiveModeAnnouncements() && isLazyLoadFlagEnabled()) {
      loadMoreAnnouncementThreads();
    }
  };

  const loadMoreAnnouncementThreads = async () => {
    try {
      if (announcementThreads.length <= totalAnnouncementThreads) {
        setLazyLoading(true);
        const lastThreadId = announcementThreads[announcementThreads.length - 1]?.threadId;
        const data = await fetchAnnouncements({
          beforeId: lastThreadId,
        });
        setTotalAnnouncementThreads((prevTotal) => data.totalThreads + prevTotal);
        setAnnouncementThreads((announcementThreads) => [...announcementThreads, ...data.threads]);
        setLazyLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const activateMessagesMode = () => {
    if (isActiveModeMessages()) {
      return;
    }
    resetConversationView();
    store.dispatch(setActiveCommunicationMode(StaffMessagingConstants.COMMUNICATION_MODES.MESSAGES));
  };

  const activateAnnouncementsMode = () => {
    if (isActiveModeAnnouncements()) {
      return;
    }
    resetConversationView();
    store.dispatch(setActiveCommunicationMode(StaffMessagingConstants.COMMUNICATION_MODES.ANNOUNCEMENTS));
  };

  const isDataLoading = () => {
    return contactsLoading || messageThreadsLoading || announcementThreadsLoading;
  };

  const getThreads = () => {
    let threads = [];
    if (isActiveModeMessages()) {
      threads = [...messageThreads];
    } else if (isActiveModeAnnouncements()) {
      threads = [...announcementThreads];
    }
    return threads;
  };

  const getTotalThreads = () => {
    let totalThreads = 0;
    if (isActiveModeMessages()) {
      totalThreads = totalMessageThreads;
    } else if (isActiveModeAnnouncements()) {
      totalThreads = totalAnnouncementThreads;
    }
    return totalThreads;
  };

  const getUnreadMessagesThreadCount = () => {
    return getUnreadCount(unreadMessageThreadsCount);
  };

  const getUnreadAnnouncementsThreadCount = () => {
    return getUnreadCount(unreadAnnouncementThreadsCount);
  };

  const isInboxEmpty = () => {
    let flag = false;
    if (isDataLoading()) {
      flag = false;
    } else if (isActiveModeMessages()) {
      flag = contacts.length === 0 || messageThreads.length === 0;
    } else if (isActiveModeAnnouncements()) {
      flag = announcementThreads.length === 0;
    }
    return flag;
  };

  const shouldShowNewMessageButton = () => {
    return isActiveModeMessages() && contacts.length > 0;
  };

  const isThreadSelected = (thread) => {
    return selectedThread?.threadId === thread?.threadId;
  };

  const resetUnreadMessages = (threads, threadId) => {
    return threads.map((thread) => {
      if (thread.threadId === threadId) {
        thread.hasUnreadMessages = false;
      }
      return thread;
    });
  };

  const handleThreadClick = (thread) => {
    store.dispatch(setSelectedThread(thread));
  };

  const markAllAnnouncementRead = () => {
    store.dispatch(setUnreadAnnouncementCount(0));
    let updatedThreads = [...announcementThreads];
    updatedThreads.map((thread) => {
      thread.hasUnreadMessages = false;
      return thread;
    });
    setAnnouncementThreads(updatedThreads);
    store.dispatch(setForceReloadMessageThreads(true));
  };

  const markAllMessagesRead = async () => {
    store.dispatch(setUnreadMessagesCount(0));
    store.dispatch(setForceReloadMessageThreads(true));
  };

  return {
    contacts,
    isActiveModeMessages,
    isLazyLoading,
    isActiveModeAnnouncements,
    unreadMessageThreadsCount,
    unreadAnnouncementThreadsCount,
    getThreads,
    getTotalThreads,
    getUnreadMessagesThreadCount,
    getUnreadAnnouncementsThreadCount,
    activateMessagesMode,
    activateAnnouncementsMode,
    isInboxEmpty,
    shouldShowNewMessageButton,
    isDataLoading,
    loadMoreThreads,
    isThreadSelected,
    handleThreadClick,
    resetUnreadMessages,
    markAllAnnouncementRead,
    markAllMessagesRead,
    relationStatusChangedState,
  };
};

export default useMessageSidebarHook;
