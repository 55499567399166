import React from 'react';

const MedicalIcon = (props) => {
  const { size } = props;
  return (
    <svg
      role="img"
      aria-hidden="true"
      width={size}
      height={size}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 0C40.3594 0 52 11.6406 52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0Z"
        fill="#0F2C54"
      />
      <path
        d="M34 20H30V18C30 16.9 29.1 16 28 16H24C22.9 16 22 16.9 22 18V20H18C16.9 20 16 20.9 16 22V34C16 35.1 16.9 36 18 36H34C35.1 36 36 35.1 36 34V22C36 20.9 35.1 20 34 20ZM24 18H28V20H24V18ZM30 29H27V32H25V29H22V27H25V24H27V27H30V29Z"
        fill="#F8F9FC"
      />
    </svg>
  );
};
MedicalIcon.defaultProps = {
  size: 52,
};

export default MedicalIcon;
