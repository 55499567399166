export default (theme) => ({
  root: {
    width: '100%',
    padding: '24px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  logo: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: 16,
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: '24px',
  },
  backButton: {
    height: '40px',
    borderRadius: '30px',
    padding: '12px 20px',
    textTransform: 'none',
    backgroundColor: theme.palette.accentTeal.dark,
    '&:hover': {
      backgroundColor: theme.palette.accentTeal.main,
    },
  },
  startOverButton: {
    height: '40px',
    borderRadius: '30px',
    padding: '12px 20px',
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  },
});
