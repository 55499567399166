import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Divider, withStyles } from '@material-ui/core';
import styles from './career-interest-selection-step.styles';
import { Constant } from '../../../../../shared';
import useCareerInterestSelectionStep from './useCareerInterestSelectionStepHook';
import { QuestionSection, FoundationSection } from '..';

const CareerInterestSelectionStep = ({ classes }) => {
  const { onExploreInterestsClick, onCareerPathClick, onFoundationClick } = useCareerInterestSelectionStep();
  const intl = useIntl();
  return (
    <Box
      data-testid="path-selection-container"
      aria-label={intl.formatMessage({ id: 'personality-quiz.title' })}
      data-tracking-id="path-selection-page"
    >
      <Box className={classes.header} data-testid="header-section">
        <img
          src={`${Constant.CDN_BASE_URL}/assets/img/program-exploration-wizard-logo.png`}
          alt="Program Finder Logo"
          className={classes.logo}
          data-testid="program-finder-logo"
        />
      </Box>
      <Box className={classes.root}>
        <Typography component="h2" variant="h2" data-testid="main-title" className={classes.mainTitle}>
          <FormattedMessage id="personality-quiz.title" />
        </Typography>

        <Typography variant="body1" className={classes.subtitle}>
          <FormattedMessage id="personality-quiz.subtitle" />
        </Typography>

        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="personality-quiz.description" />
        </Typography>

        <Box py={4}>
          <Divider />
        </Box>

        <QuestionSection
          data-testid="question-section"
          onExploreInterestsClick={onExploreInterestsClick}
          onCareerPathClick={onCareerPathClick}
        />
        <Box py={4}>
          <Divider />
        </Box>
        <FoundationSection
          data-testid="foundation-section"
          onClick={onFoundationClick}
          buttonText={<FormattedMessage id="personality-quiz.foundation.button" />}
        />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(CareerInterestSelectionStep);
