import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import useStyles from './interest-profiler-action.styles';
import useInterestProfilerActionHook from './useInterestProfilerActionHook';

const InterestProfilerAction = ({ answersString }) => {
  const { interestProfileArea, handleClick } = useInterestProfilerActionHook(answersString);
  const classes = useStyles({ interestProfileArea });

  return (
    <Box className={classes.container} data-testid="interest-profiler-action">
      <Typography variant="body1" className={classes.title} data-testid="profiler-title">
        {interestProfileArea ? (
          <FormattedMessage id="interest-profiler-action.title.completed" />
        ) : (
          <FormattedMessage id="interest-profiler-action.title.default" />
        )}
      </Typography>
      <Typography variant="body1" className={classes.description} data-testid="profiler-description">
        {interestProfileArea ? (
          <>
            <FormattedMessage id="interest-profiler-action.description.completed" />
            <Typography component="span" className={classes.interestProfiler} data-testid="interest-profiler-text">
              {interestProfileArea}
            </Typography>
          </>
        ) : (
          <FormattedMessage id="interest-profiler-action.description.default" />
        )}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleClick}
        data-testid="profiler-button"
      >
        {interestProfileArea ? (
          <FormattedMessage id="interest-profiler-action.button.completed" />
        ) : (
          <FormattedMessage id="interest-profiler-action.button.default" />
        )}
      </Button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  answersString: state.interestProfiler.answersString,
});

export default connect(mapStateToProps)(InterestProfilerAction);
