import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, withStyles } from '@material-ui/core';
import OptionBox from '../option-box/option-box';
import styles from './question-section.styles';
import { Constant } from '../../../../../shared';
import PropTypes from 'prop-types';

const QuestionSection = ({ classes, onExploreInterestsClick, onCareerPathClick }) => {
  return (
    <>
      <Typography component="h2" variant="h2" className={classes.questionTitle} data-testid="question-title">
        <FormattedMessage id="personality-quiz.question1.title" />
      </Typography>

      <Typography variant="body1" className={classes.questionDescription} data-testid="question-description">
        <FormattedMessage id="personality-quiz.question1.description" />
      </Typography>

      <Box className={classes.optionsContainer} data-testid="options-container">
        <OptionBox
          imageUrl={`${Constant.CDN_BASE_URL}/assets/img/option-img-interest.png`}
          imageAlt="Explore Interests"
          buttonText={<FormattedMessage id="personality-quiz.option1.button" />}
          onClick={onExploreInterestsClick}
        />
        <OptionBox
          imageUrl={`${Constant.CDN_BASE_URL}/assets/img/option-img-career.png`}
          imageAlt="Career Path"
          buttonText={<FormattedMessage id="personality-quiz.option2.button" />}
          onClick={onCareerPathClick}
        />
      </Box>
    </>
  );
};

QuestionSection.propTypes = {
  onExploreInterestsClick: PropTypes.func.isRequired,
  onCareerPathClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuestionSection);
