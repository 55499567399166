import React, { forwardRef } from 'react';
import { Modal } from '@orijinworks/frontend-commons';
import { Typography } from '@material-ui/core';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import { Box } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
const ArchiveUnArchiveContactModal = forwardRef(({ isOpen, onClose, contactName, onContinue }, ref) => {
  const intl = useIntl();
  return (
    <Modal
      ref={ref}
      open={isOpen}
      showCrossIcon={true}
      onClose={onClose}
      actionBtnText={intl.formatMessage({ id: 'archiveContact' })}
      cancelBtnText={intl.formatMessage({ id: 'cancel' })}
      onContinue={onContinue}
      styles={{
        mainWrapperStyles: {
          height: '350px',
          width: '420px',
          minWidth: '420px',
          maxWidth: '420px',
          zIndex: 9999,
          backdropFilter: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }}
      modalType={{
        type: 'confirmation',
        status: 'danger',
      }}
      Icon={<ArchiveOutlinedIcon />}
    >
      <Box>
        <Typography variant="h5" gutterBottom>
          <b>
            <FormattedMessage id="archiveContact" />
            {contactName} ?
          </b>
        </Typography>

        <Typography variant="body1" gutterBottom>
          <FormattedMessage id="archiveContactMessage" />
        </Typography>
      </Box>
    </Modal>
  );
});

export default ArchiveUnArchiveContactModal;
