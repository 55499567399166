import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { InterestProfilerService } from '../../../../../content/interest-profiler/services/interest-profiler.service';

const useInterestProfilerActionHook = (answersString) => {
  const [interestProfileArea, setInterestProfileArea] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchResults = async () => {
      if (answersString) {
        const service = new InterestProfilerService();
        const resultsData = await service.getInterestProfilerResults(answersString);
        setInterestProfileArea(resultsData?.final_result?.area);
      }
    };

    fetchResults();
  }, [answersString]);

  const handleClick = useCallback(() => {
    if (interestProfileArea) {
      history.push(`/personality-quiz/${interestProfileArea.toLowerCase()}`);
    } else {
      history.push('/interest-profiler');
    }
  }, [interestProfileArea, history]);

  return {
    interestProfileArea,
    handleClick,
  };
};

export default useInterestProfilerActionHook;
