import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FeatureContentService, HomeService } from '../../services';
import { Constant, Utility } from '../../../../shared';
import styles from './home.style';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  FeaturedContents,
  RecommendedContent,
  MyPrograms,
  QuickLinks,
  InterestProfilerTile,
  PinnedCareerPaths,
  CareerInterestQuizCTA,
} from '../../components';
import { GettingStarted } from '../../components/';
import { PDQAssessmentTile } from '../../../pdq-assessment';
import { PDQAssessmentService } from '../../../pdq-assessment/services/pdq-assessment.service';
import { DocumentTitle } from '../../../../v2/shared';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { FeaturedContents as FeaturedContentsV2 } from '../../../../v2/content';
import { CareerCompassBanner } from '../../../../v2/content/career-compass';

export class Home extends React.Component {
  _service = new FeatureContentService();
  _utilityService = new Utility();
  _homeService = new HomeService();
  _pdqService = new PDQAssessmentService();
  state = {
    featuredContents: [],
    recommendedContent: null,
    openPDQInfoModal: false,
  };

  componentDidMount() {
    this.getFeaturedContents();
    this.getRecommendedContent();
  }

  getRecommendedContent = async () => {
    const recommendedContent = await this._homeService.getRecommendedContent();
    this.setState({
      recommendedContent: null,
    });
  };

  launchRecommendedContent = async (resourceId) => {
    try {
      this.props.history.push(`/programs/${resourceId}/details`);
    } catch (e) {
      console.log(e);
    }
  };
  /**
   * @name getFeaturedContents
   * @desc Purpose of this method is to fetch all featured contents
   * @return {void}
   */
  getFeaturedContents = () => {
    this._service.getFeaturedConetents().then((data) => {
      const { featuredContents } = data;
      if (featuredContents && featuredContents.length > 0) {
        this.setState({ featuredContents });
      }
    });
  };

  getString = (type, text) => {
    const phrase = {
      [Constant.PDQ_ENUM.NOT_STARTED]: {
        title: this.props.intl.formatMessage({ id: 'pdqIntroTitle' }),
        description: this.props.intl.formatMessage({ id: 'pdqIntroDescription' }),
        button: this.props.intl.formatMessage({ id: 'pdqButtonText' }),
      },
      [Constant.PDQ_ENUM.IN_PROGRESS]: {
        title: this.props.intl.formatMessage({ id: 'pdqRemindTitle' }),
        description: this.props.intl.formatMessage({ id: 'pdqRemindDescription' }),
        button: this.props.intl.formatMessage({ id: 'pdqRemindButtonText' }),
      },
      [Constant.PDQ_ENUM.SUBMITTED]: {
        title: this.props.intl.formatMessage({ id: 'pdqSubmittedTitle' }),
        description: this.props.intl.formatMessage({ id: 'pdqSubmittedDescription' }),
      },
      [Constant.PDQ_ENUM.COMPLETED]: {
        title: this.props.intl.formatMessage({ id: 'pdqCompleteTitle' }),
        description: this.props.intl.formatMessage({ id: 'pdqCompleteDescription' }),
        button: this.props.intl.formatMessage({ id: 'pdqCompleteButtonText' }),
      },
    };
    return phrase[type][text];
  };

  /**
   * @name callLaunchUrl
   * @desc Purpose of this method is to redirect users to featured content's website
   * @return {void}
   */
  callLaunchUrl = (url) => {
    if (this._utilityService.isRelativePath(url)) {
      const path = this._utilityService.extractRelativePath(url);
      this.props.history.push(path);
    } else {
      window.open(url, '_blank');
    }
  };

  openTranscriptModal = () => {
    this.props.history.push('/transcript');
  };

  goToBookmarks = () => {
    this.props.history.push('/bookmarks');
  };

  launchInterestProfiler = () => {
    this.props.history.push('/interest-profiler');
  };

  viewInterestProfilerResults = () => {
    this.props.history.push('/careers');
  };

  launchPDQAssessment = () => {
    this._pdqService.launchPDQAssessment(() => {
      this.setState({ openPDQInfoModal: true });
    });
  };

  handleInfoModalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      this.setState({ openPDQInfoModal: false });
    }
  };

  render() {
    const { classes, intl, pdqAssessmentStatus } = this.props;
    const { recommendedContent } = this.state;

    const WelcomeText = (
      <Typography component="h2" variant="h2" id="main-title" className={classes.mainTitle}>
        <FormattedMessage
          id="greetWelcome"
          values={{
            name: this.props.userDetail.firstName || this.props.userDetail.userName,
          }}
        />
      </Typography>
    );

    return (
      <Grid container item sm={12}>
        <DocumentTitle title="page.title.home" />

        <Dialog open={this.state.openPDQInfoModal} onClose={this.handleInfoModalClose}>
          <Box className={classes.modalContent}>
            <Typography gutterBottom>
              <FormattedMessage id="pdq-assessment.relaunch.info-message.text.homepage" />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleInfoModalClose}
              className={classes.modalButton}
              aria-label={intl.formatMessage({ id: 'ok' })}
            >
              <FormattedMessage id="ok" />
            </Button>
          </Box>
        </Dialog>

        {/* BEGIN: TOP ROW */}
        <Box maxWidth="100%" width="100%" margin="60px 0px 0px 0px" display="flex">
          <Grid container sm={12} md={12} lg={12} xl={12}>
            <Grid item sm={12} md={8} lg={5} xl={7}>
              <GettingStarted welcomeText={WelcomeText} />
            </Grid>
          </Grid>
        </Box>
        <CareerInterestQuizCTA />
        <CareerCompassBanner />
        <Box maxWidth="100%" width="100%" margin="20px 0px 0px 0px" display="flex">
          <Grid container sm={12} md={12} lg={12} xl={12}>
            <Grid item sm={12} md={12} lg={5} xl={7}>
              {pdqAssessmentStatus && (
                <PDQAssessmentTile
                  showInstructions={() => this.props.history.push('/pdq-assessment/intro')}
                  launchAssessment={this.launchPDQAssessment}
                  showResults={() => this.props.history.push('/pdq-assessment/results')}
                  title={this.getString(pdqAssessmentStatus, 'title')}
                  description={this.getString(pdqAssessmentStatus, 'description')}
                  action1Text={this.getString(pdqAssessmentStatus, 'button')}
                  status={pdqAssessmentStatus}
                />
              )}
            </Grid>
            <Grid item sm={6} md={4} lg={4} xl={3} className={classes.interestProfilerTileContainer}>
              <InterestProfilerTile
                isInterestProfilerTaken={!!this.props.interestProfiler.answersString}
                onLaunch={this.launchInterestProfiler}
                onViewResults={this.viewInterestProfilerResults}
              />
            </Grid>
            <Grid item sm={6} md={4} lg={3} xl={2} className={classes.quickLinksContainer}>
              <QuickLinks openTranscriptModal={this.openTranscriptModal} openBookmarks={this.goToBookmarks} />
            </Grid>
          </Grid>
        </Box>
        {/* END: TOP ROW */}

        {recommendedContent && (
          <RecommendedContent
            recommendedContent={recommendedContent}
            description={this.getString(Constant.PDQ_ENUM.COMPLETED, 'description')}
            onLaunchUrl={this.launchRecommendedContent}
          />
        )}

        {this._utilityService.checkStatusExists(this.props.features, Constant.FEATURES_ENUM.CAREER_PATH) && (
          <PinnedCareerPaths {...this.props} />
        )}

        {this.props.unleashProps.isFlagEnabled() ? (
          <FeaturedContentsV2
            features={this.props.features}
            username={this.props.userDetail?.userName}
            history={this.props.history}
          />
        ) : (
          this.state.featuredContents.length > 0 && (
            <FeaturedContents
              contents={this.state.featuredContents}
              callLaunchUrl={this.callLaunchUrl}
              {...this.props.userDetail}
            />
          )
        )}

        <MyPrograms />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    selectedLocale: state.locale.locale,
    interestProfiler: state.interestProfiler,
    pdqAssessmentStatus: state.pdqAssessment.pdqAssessmentStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withUnleash(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(injectIntl(Home)))),
  UnleashService.FLAGS.FEATURED_CONTENT_V2
);
