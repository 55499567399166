export const AUTH_CONSTANTS = {
  /**
   * List of Keycloak Roles.
   */
  KEYCLOAK_ROLES: {
    ADMIN: 'apds_horizon_role',
    LEARNER: 'apds_connected_corrections_role',
  },

  /**
   * List of Auth0 Roles.
   */
  AUTH0_ROLES: {
    ADMIN: 'admin',
    LEARNER: 'learner',
  },

  /**
   * Token refresh threshold in minutes.
   * If the token expires in less than this threshold, the token will be refreshed.
   */
  TOKEN_REFRESH_THRESHOLD: 5,

  /**
   * List of Auth Guards results.
   */
  AUTH_GUARDS_RESULT: {
    GRANT_ACCESS: 'GRANT_ACCESS',
    DENY_ACCESS: 'DENY_ACCESS',
  },

  /**
   * List of Auth Guards actions.
   */
  AUTH_GUARDS_ACTION: {
    BLOCK_ACCESS: 'BLOCK_ACCESS',
    PERSIST: 'PERSIST',
    AUTHENTICATE: 'AUTHENTICATE',
    REGISTER: 'REGISTER',
  },

  /**
   * List of Auth Guards.
   */
  AUTH_GUARDS_LIST: {
    NETWORK_GUARD: 'NETWORK_GUARD',
    LEARNER_PORTAL_ACCESS: 'LEARNER_PORTAL_ACCESS_GUARD',
    USER_DEVICE_PAIRING: 'USER_DEVICE_PAIRING_GUARD',
    TERMS_OF_SERVICE: 'TERMS_OF_SERVICE_GUARD',
    BIOMETRIC_AUTHENTICATION: 'BIOMETRIC_AUTHENTICATION_GUARD',
  },

  /**
   * List of Biometric Authentication face positions mapping.
   */
  FACE_POSITION: {
    FRONT: 'front',
    LEFT: 'left',
    RIGHT: 'right',
    UP: 'up',
    DOWN: 'down',
  },

  /**
   * List of Biometric Authentication face positions array.
   */
  FACE_POSITIONS_ARRAY: ['front', 'left', 'right', 'up', 'down'],

  /**
   * List of Biometric Authentication webcam errors.
   */
  WEBCAM_ERROR: {
    NOT_FOUND: 'NotFoundError',
    NOT_ALLOWED: 'NotAllowedError',
  },

  /**
   * Capture image timer in seconds.
   * The timer will countdown from this value to 0.
   */
  CAPTURE_IMAGE_TIMER: 3,

  /**
   * List of Biometric Authentication face registration image count.
   * The user needs to capture this number of images for face registration.
   */
  FACE_REGISTRATION_IMAGE_COUNT: 5,

  /**
   * List of Biometric Authentication face validation errors.
   */
  FACE_VALIDATION_ERROR: {
    FACE_NOT_FOUND: 'FACE_NOT_FOUND',
    MULTIPLE_FACES_FOUND: 'MULTIPLE_FACES_FOUND',
    ACCESSORIES_FOUND: 'ACCESSORIES_FOUND',
    BAD_EXPRESSIONS: 'BAD_EXPRESSIONS',
    BAD_IMAGE_QUALITY: 'BAD_IMAGE_QUALITY',
    FACE_ALREADY_REGISTERED: 'FACE_ALREADY_REGISTERED',
    DIFFERENT_FACE_FOUND: 'DIFFERENT_FACE_FOUND',
    FACE_REGISTRATION_FAILED: 'FACE_REGISTRATION_FAILED',
    FACE_MISMATCH: 'FACE_MISMATCH',
  },

  /**
   * List of Biometric Authentication face validation error messages.
   * These messages are intl keys defined in the translation files.
   * Should be used with the FormattedMessage component or formatMessage function.
   */
  FACE_VALIDATION_ERROR_MESSAGES: {
    FACE_NOT_FOUND: 'biometric-authentication.face-validation-error.face-not-found',
    MULTIPLE_FACES_FOUND: 'biometric-authentication.face-validation-error.multiple-faces-found',
    ACCESSORIES_FOUND: 'biometric-authentication.face-validation-error.accessories-found',
    BAD_EXPRESSIONS: 'biometric-authentication.face-validation-error.bad-expressions',
    BAD_IMAGE_QUALITY: 'biometric-authentication.face-validation-error.bad-image-quality',
    FACE_ALREADY_REGISTERED: 'biometric-authentication.face-validation-error.face-already-registered',
    DIFFERENT_FACE_FOUND: 'biometric-authentication.face-validation-error.different-face-found',
    FACE_REGISTRATION_FAILED: 'biometric-authentication.face-validation-error.face-registration-failed',
    FACE_MISMATCH: 'biometric-authentication.face-validation-error.face-mismatch',
    SOMETHING_WENT_WRONG: 'biometric-authentication.face-validation-error.something-went-wrong',
  },

  /**
   * List of Biometric Authentication face validation statuses.
   */
  FACE_VALIDATION_STATUS: {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
  },

  /**
   * List of Auth0 error types that require logout.
   */
  AUTH0_LOGOUT_ERROR_TYPES: [
    'login_required',
    'invalid_grant',
    'missing_refresh_token',
    'invalid_client',
    'unauthorized_client',
    'access_denied',
  ],

  /**
   * List of Authentication error types.
   */
  AUTH_ERROR_TYPES: {
    USER_LOGGED_OUT: 'user_logged_out',
  },

  /**
   * Auth session correlation key. Used in storing the auth session correlation in localStorage.
   */
  AUTH_SESSION_CORRELATION_KEY: 'auth_session_correlation',
};
