import { Http, store, setContacts } from '../../../core';
import { Constant, Utility } from '../../../shared/services';

class StaffMessagingConstants {
  static UNREAD_THREAD_COUNT_MAX_LIMIT = 100;
  static MESSAGE_FETCH_LIMIT = 30;
  static DEFAULT_UNREAD_MESSAGE_COUNT_LIMIT = 3;

  /**
   * @name COMMUNICATION_MODES
   * @description Communication modes for staff messaging
   * @returns {Object}
   */
  static get COMMUNICATION_MODES() {
    return {
      MESSAGES: 'MESSAGES',
      ANNOUNCEMENTS: 'ANNOUNCEMENTS',
    };
  }

  /**
   * @name DEFAULT_CHARACTER_LIMIT
   * @description Default character limit for staff messaging
   * @returns {Object}
   */
  static get DEFAULT_CHARACTER_LIMIT() {
    return {
      MIN: 1,
      MAX: 1000,
    };
  }

  /**
   * @name MESSAGE_TYPES
   * @description Message types for staff messaging
   * @returns {Object}
   */
  static get MESSAGE_TYPES() {
    return {
      MESSAGE: 'message',
      DATE_SEPARATOR: 'date-separator',
    };
  }
}

/**
 * @name transformMessageThreads
 * @description Transforms message threads
 * @param {Array} threads
 * @returns {Array}
 */
const transformMessageThreads = (threads) => {
  return threads.map((thread) => {
    const {
      threadId,
      contactId,
      contactName,
      unreadMessageCount,
      roleType,
      roleName,
      recentMessageDate,
      recentMessageClearBody,
      unreadMessageCountLimit,
      messageRelationshipStatus,
    } = thread;
    return {
      threadId,
      contactId,
      title: contactName,
      hasUnreadMessages: Boolean(unreadMessageCount),
      roleName,
      roleType,
      lastMessage: { datetime: Utility.transformDateTime(recentMessageDate), body: recentMessageClearBody },
      showMessagePreview: true,
      showSenderRecipient: false,
      unreadMessageCountLimit,
      messageRelationshipStatus,
    };
  });
};

/**
 * @name extractRecipient
 * @description Extracts recipient from thread
 * @param {Object} thread
 * @returns {String}
 */
const extractRecipient = (thread) => {
  const { locationId, locationName } = thread;
  return locationId ? locationName : 'You + several others';
};

/**
 * @name transformAnnouncementThreads
 * @description Transforms announcement threads
 * @param {Array} threads
 * @returns {Array}
 */
const transformAnnouncementThreads = (threads) => {
  return threads.map((thread) => {
    const { id, title, body, senderPersonName, createdDate, readDate } = thread;
    return {
      threadId: id,
      title: title || body,
      announcementTitle: title,
      sender: senderPersonName,
      recipient: extractRecipient(thread),
      hasUnreadMessages: !Boolean(readDate),
      lastMessage: { datetime: Utility.transformDateTime(createdDate), body: body },
      showMessagePreview: false,
      showSenderRecipient: true,
    };
  });
};

/**
 * @name transformMessages
 * @description Transforms messages
 * @param {Array} messages
 * @returns {Array}
 */
const transformMessages = (messages) => {
  return messages.map((message) => {
    const {
      threadId,
      messageId,
      body,
      senderPersonId,
      senderPersonName,
      recipientPersonId,
      recipientPersonName,
      readDate,
      createdDate,
      recordStatus,
    } = message;
    return {
      messageId,
      threadId,
      body,
      senderId: senderPersonId,
      senderName: senderPersonName,
      recipientId: recipientPersonId,
      recipientName: recipientPersonName,
      isHidden: !Boolean(recordStatus),
      readDate: readDate ? Utility.transformDateTime(readDate) : null,
      datetime: Utility.transformDateTime(createdDate),
    };
  });
};

/**
 * @name isRoleAttorney
 * @description Checks if role is attorney
 * @param {String} roleName
 * @param {String} roleType
 * @returns {Boolean}
 */
const isRoleAttorney = (roleName, roleType) => {
  return [roleName, roleType].includes(Constant.ROLE_TYPES.ATTORNEY);
};

/**
 * @name getUnreadCount
 * @description Gets unread count
 * @param {Number} unreadCount
 * @returns {String}
 */
const getUnreadCount = (unreadCount) => {
  let count = '';
  if (unreadCount >= StaffMessagingConstants.UNREAD_THREAD_COUNT_MAX_LIMIT) {
    count = '(99+)';
  } else if (unreadCount > 0) {
    count = `(${unreadCount})`;
  }
  return count;
};

/**
 * @name fetchContacts
 * @description Fetches contacts aka messaging relationships
 * @param {String} searchKey
 * @returns {Promise}
 */
const fetchContacts = (searchKey) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.get(`/contacts/search?noLoader=true&search_key=${searchKey}`)
      .then((_successLog) => {
        if (_successLog?.data?.length > 0) {
          store.dispatch(setContacts(_successLog.data));
          resolve(_successLog.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name fetchMessageThreads
 * @description Fetches message threads
 * @param {String} orderBy
 * @returns {Promise}
 */
const fetchMessageThreads = () => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.get(`/v2/threads?noLoader=true&order_by=recent_message&sort_by=desc`)
      .then((_successLog) => {
        if (_successLog?.data?.messageThreads?.length > 0) {
          const threads = transformMessageThreads(_successLog.data.messageThreads);
          resolve({
            threads,
            totalThreads: threads.length,
          });
        }
        resolve({
          threads: [],
          totalThreads: 0,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name fetchAnnouncements
 * @description Fetches announcements threads
 * @param {Number} beforeId
 * @param {Number} afterId
 * @param {Number} limit
 * @returns {Promise}
 */
const fetchAnnouncements = (params = {}) => {
  const { beforeId, limit = StaffMessagingConstants.MESSAGE_FETCH_LIMIT } = params;
  return new Promise((resolve, reject) => {
    let url = '/v2/announcements?noLoader=true&order_by=recent_announcement&sort_by=desc&';
    if (beforeId) url = `${url}before_id=${beforeId}&`;
    if (limit) url = `${url}limit=${limit}`;

    Http.HERMES_REQUEST.get(url)
      .then((_successLog) => {
        if (_successLog?.data?.announcements?.length > 0) {
          resolve({
            threads: transformAnnouncementThreads(_successLog.data.announcements),
            totalThreads: _successLog.data.announcementCount,
          });
        }
        resolve({
          threads: [],
          totalThreads: -1,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name fetchMessages
 * @description Fetches messages by thread key
 * @param {Number} threadId
 * @param {Number} beforeId
 * @param {Number} afterId
 * @param {Number} limit - default 30
 * @returns {Promise}
 */
const fetchMessages = (threadId, beforeId, afterId, limit = StaffMessagingConstants.MESSAGE_FETCH_LIMIT) => {
  return new Promise((resolve, reject) => {
    let url = `/messages?noLoader=true&thread_key=${threadId}&`;
    if (beforeId) url = `${url}before_id=${beforeId}&`;
    if (afterId) url = `${url}after_id=${afterId}&`;
    if (limit) url = `${url}limit=${limit}`;

    Http.HERMES_REQUEST.get(url)
      .then((_successLog) => {
        if (_successLog?.data?.length > 0) {
          resolve(transformMessages(_successLog.data));
        }
        resolve([]);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name fetchCharacterLimit
 * @description Fetches character limit
 * @returns {Promise}
 */
const fetchCharacterLimit = () => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.get(`/messages/location/characterslimit?noLoader=true`)
      .then((_successLog) => {
        if (_successLog?.data) {
          resolve({
            minCharLimit: _successLog.data.messagingMinCharacterLimit,
            maxCharLimit: _successLog.data.messagingMaxCharacterLimit,
          });
        }

        resolve({
          minCharLimit: StaffMessagingConstants.DEFAULT_CHARACTER_LIMIT.MIN,
          maxCharLimit: StaffMessagingConstants.DEFAULT_CHARACTER_LIMIT.MAX,
        });
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name markAnnouncementAsRead
 * @description Marks announcement as read
 * @param {Number} announcementId
 * @returns {Promise}
 */
const markAnnouncementAsRead = (announcementId) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.put(`/v2/announcements/${announcementId}?noLoader=true`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name markMessagesAsRead
 * @description Marks messages as read
 * @param {Number} threadId
 * @param {Number} recentMessageId
 * @returns {Promise}
 */
const markMessagesAsRead = (threadId, recentMessageId) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.put(`/messages?thread_key=${threadId}&recent_message_id=${recentMessageId}&noLoader=true`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((_errorLog) => {
        reject(_errorLog);
      });
  });
};

/**
 * @name sendMessage
 * @description Sends message
 * @param {Object} payload
 * @returns {Promise}
 */
const sendMessage = (payload) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.post('/messages?noLoader=true', payload)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((_errorLog) => reject(_errorLog));
  });
};

/**
 * @name hideMessage
 * @description Hides message
 * @param {Number} messageId
 * @returns {Promise}
 */
const hideMessage = (messageId) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.delete(`/messages?noLoader=true&message_id=${messageId}`)
      .then((_successLog) => {
        resolve(true);
      })
      .catch((error) => {
        reject(false);
      });
  });
};

/**
 * @name unhideMessage
 * @description Unhides message
 * @param {Number} messageId
 * @returns {Promise}
 */
const unhideMessage = (messageId) => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.put(`/messages/${messageId}/unhide?noLoader=true`)
      .then((_successLog) => {
        resolve(true);
      })
      .catch((error) => {
        reject(false);
      });
  });
};

/**
 * @name markAllMessagesRead
 * @description Unhides message
 * @param {Number}
 * @returns {Promise}
 */
const markAllMessagesRead = () => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.put(`/messages/mark-read`)
      .then((_successLog) => {
        resolve(true);
      })
      .catch((error) => {
        reject(false);
      });
  });
};

/**
 * @name markAllAnnouncementRead
 * @description Unhides message
 * @param {Number}
 * @returns {Promise}
 */
const markAllAnnouncementRead = () => {
  return new Promise((resolve, reject) => {
    Http.HERMES_REQUEST.put(`/announcements/mark-read`)
      .then((_successLog) => {
        resolve(true);
      })
      .catch((error) => {
        reject(false);
      });
  });
};

export {
  StaffMessagingConstants,
  isRoleAttorney,
  getUnreadCount,
  fetchContacts,
  fetchMessageThreads,
  fetchAnnouncements,
  fetchMessages,
  fetchCharacterLimit,
  markAnnouncementAsRead,
  markMessagesAsRead,
  sendMessage,
  hideMessage,
  unhideMessage,
  markAllMessagesRead,
  markAllAnnouncementRead,
  transformAnnouncementThreads,
};
