import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './foundation-section.styles';

const FoundationSection = ({ classes, onClick, buttonText }) => {
  const intl = useIntl();
  return (
    <>
      <Typography variant="body2" className={classes.foundationNote} data-testid="foundation-note">
        <FormattedMessage id="personality-quiz.foundation.note" />
      </Typography>

      <Box className={classes.foundationButton} data-testid="foundation-button-container">
        <Button
          variant="outlined"
          className={classes.button}
          data-testid="foundation-button"
          aria-label={typeof buttonText === 'object' ? intl.formatMessage(buttonText.props) : buttonText}
          onClick={onClick}
        >
          {buttonText || <FormattedMessage id="personality-quiz.foundation.button" />}
        </Button>
      </Box>
    </>
  );
};

FoundationSection.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(styles)(FoundationSection);
