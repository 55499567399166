import { useEffect, useRef } from 'react';
import { Constant } from '../../../../../shared/services';

/**
 * Custom hook that contains the logic for the category details component.
 *
 * @param {number} props.categoryId - The category id.
 * @param {object[]} props.services - The services.
 * @param {number} props.total - The total number of services.
 * @param {function} props.handleActiveCategory - The function to handle the active category.
 * @returns {object} categoryRef - The categoryRef object.
 * @returns {boolean} showThumbnailTiles - The showThumbnailTiles boolean.
 * @returns {boolean} showIconTiles - The showIconTiles boolean.
 * @returns {boolean} showCarousel - The showCarousel boolean.
 * @returns {boolean} showTools - The showTools boolean.
 */
const useCategoryDetailsHook = ({ categoryId, services, total, handleActiveCategory }) => {
  const categoryRef = useRef(null);

  /**
   * useEffect that observes categoryRef and handles the active category when it is intersecting.
   */
  useEffect(() => {
    const element = categoryRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          handleActiveCategory(categoryId, false);
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [categoryId, handleActiveCategory]);

  /**
   * Function that determines if the thumbnail cards should be shown.
   *
   * @returns {boolean}
   */
  const showThumbnailCards = () =>
    [
      Constant.SERVICE_CATEGORIES_ENUM.EDUCATION,
      Constant.SERVICE_CATEGORIES_ENUM.MASTER_CLASS,
      Constant.SERVICE_CATEGORIES_ENUM.WORKFORCE_SKILLS,
      Constant.SERVICE_CATEGORIES_ENUM.PERSONAL_DEVELOPMENT,
    ].includes(categoryId);

  /**
   * Function that determines if the carousel should be shown.
   *
   * @returns {boolean}
   */
  const showCarousel = () =>
    !showThumbnailCards() &&
    [Constant.SERVICE_CATEGORIES_ENUM.REHABILITATION, Constant.SERVICE_CATEGORIES_ENUM.DOCUMENTRIES].includes(categoryId);

  /**
   * Function that determines if the tools should be shown.
   *
   * @returns {boolean}
   */
  const showTools = () => !showCarousel() && [Constant.SERVICE_CATEGORIES_ENUM.RESOURCES].includes(categoryId);

  /**
   * Function that determines if the icon should be shown as a thumbnail.
   *
   * @returns {boolean}
   */
  const showIconAsThumbnail = () => [Constant.SERVICE_CATEGORIES_ENUM.PERSONAL_DEVELOPMENT].includes(categoryId);

  /**
   * Function that determines if there are more services.
   *
   * @returns {boolean}
   */
  const hasMoreServices = () => total > services.length;

  /**
   * Function that determines if the show all button should be displayed.
   *
   * @returns {boolean}
   */
  const displayShowAll = () => !(showCarousel() || showTools()) && hasMoreServices();

  return {
    categoryRef,
    showThumbnailCards,
    showCarousel,
    showTools,
    showIconAsThumbnail,
    displayShowAll,
  };
};

export default useCategoryDetailsHook;
