import { Box, Link, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './call-usage-banner.style';
import { InfoIcon, Typography } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import TimerIcon from '@mui/icons-material/Timer';

const CallUsageBanner = ({
  classes,
  maxCallDurationPerCall,
  maxCallDurationPerDay,
  consumedCallDurationPerDay,
  remainingCallDurationPerDay,
  bannerType,
  tabActive,
  children,
  viewFullSchedule,
  handleShowFullSchedule,
}) => {
  // log all props
  const renderBannerComponent = (bannerType, tabActive) => {
    switch (bannerType) {
      case 'CALL_TIME_LIMIT_NOT_REACHED':
        return (
          <>
            <Box className={classes.root} display={'flex'} flexDirection={'row'}>
              <TimerIcon />
              <Box display={'flex'} flexDirection={'column'} className={classes.gap}>
                <Typography variant="h2" data-testid="remaining-time" className={classes.timeRemainingText}>
                  <FormattedMessage
                    id="callTimeRemaining"
                    values={{
                      span: (...chunks) => <span className={classes.timeLimitRemaining}>{chunks}</span>,
                      remainingTime: remainingCallDurationPerDay,
                    }}
                  />
                </Typography>
                <Typography variant="body1" data-testid="consume-time">
                  <FormattedMessage
                    id="callTimeUsed"
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      consumedCallDuration: parseInt(consumedCallDurationPerDay),
                      maxCallPerDay: maxCallDurationPerDay,
                    }}
                  />
                </Typography>
                <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                  <InfoIcon />
                  &nbsp;
                  <Typography variant="body1" data-testid="max-call-duration">
                    <FormattedMessage
                      id="callLimitMessage"
                      values={{
                        maxCallDuration: maxCallDurationPerCall,
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        );
      case 'CALL_TIME_LIMIT_REACHED':
        return (
          <>
            <Box className={classes.root} display={'flex'} flexDirection={'row'}>
              <TimerIcon className={classes.timeLimitNotRemaining} />
              <Box display={'flex'} flexDirection={'column'} style={{ gap: '8px' }}>
                <Typography variant="h2" data-testid="remaining-time" className={classes.timeLimitNotRemaining}>
                  Call Time Limit Reached
                </Typography>
                <Typography variant="body1" data-testid="consume-time">
                  {tabActive === 'Contacts' ? (
                    <>
                      You have reached your allocated call time for the day. <br></br>To find the next opening,{' '}
                      <Link
                        data-testid="view-full-schedule"
                        underline="none"
                        className={classes.link}
                        onClick={handleShowFullSchedule}
                      >
                        <FormattedMessage id="callbanner.viewSchedule" />
                      </Link>
                    </>
                  ) : (
                    <>
                      You have used <b>{parseInt(consumedCallDurationPerDay)}</b> of <b>{maxCallDurationPerDay}</b> minutes
                      available.
                    </>
                  )}
                </Typography>
                {tabActive === 'Contacts' && viewFullSchedule ? children : null}
              </Box>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return renderBannerComponent(bannerType, tabActive);
};

export default withStyles(styles)(CallUsageBanner);
