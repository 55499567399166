import { useEffect, useState } from 'react';
import { StaffMessagingConstants, fetchCharacterLimit, sendMessage } from '../../services/staff-messaging.service';
import { setFetchNewMessages, setForceReloadMessageThreads, setSelectedContact, store, toggleToast } from '../../../../core';

const useConversationViewHook = ({ isComposing, showConversation, staffMessaging }) => {
  const { selectedThread, selectedContact, unreadMessagesCountLimitReached: messageSendLimitReached } = staffMessaging;
  const messageSendLimit = selectedThread?.unreadMessageCountLimit;
  const messageRelationshipStatus = selectedThread?.messageRelationshipStatus;
  const [characterLimit, setCharacterLimit] = useState({
    min: StaffMessagingConstants.DEFAULT_CHARACTER_LIMIT.MIN,
    max: StaffMessagingConstants.DEFAULT_CHARACTER_LIMIT.MAX,
  });

  useEffect(() => {
    getCharacterLimit();
  }, []);

  const getCharacterLimit = async () => {
    try {
      const { minCharLimit, maxCharLimit } = await fetchCharacterLimit();
      setCharacterLimit({ min: minCharLimit, max: maxCharLimit });
    } catch (e) {
      console.log(e);
    }
  };

  const handleErrorResponse = (e) => {
    let message = '';
    switch (e?.response?.status) {
      case 429:
        message = 'messageLimitError';
        break;
      default:
        message = 'sendMessageFailed';
        break;
    }
    store.dispatch(
      toggleToast({
        variant: 'error',
        message,
        open: true,
        isOpen: true,
        showCancelButton: true,
      })
    );
  };

  const onSend = async (text, cb) => {
    try {
      let contact = selectedThread;
      if (isComposing) {
        contact = selectedContact;
      }

      const payload = {
        body: text,
        to_person_key: contact.contactId,
        is_announcement: false,
      };
      await sendMessage(payload);
      store.dispatch(setForceReloadMessageThreads(true));

      if (!isComposing) {
        store.dispatch(setFetchNewMessages(true));
      }
      if (cb) cb();
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleContactSelect = (contact) => {
    store.dispatch(setSelectedContact(contact));
  };

  const shouldDisableSendButton = () => {
    let flag = false;
    if (isComposing) {
      flag = !Boolean(selectedContact);
    }
    return flag;
  };

  const showConversationHistory = () => {
    return !isComposing && showConversation;
  };

  return {
    characterLimit,
    messageSendLimitReached,
    messageSendLimit,
    messageRelationshipStatus,
    handleContactSelect,
    shouldDisableSendButton,
    onSend,
    showConversationHistory,
  };
};

export default useConversationViewHook;
